import _ from 'lodash';
import { IMetricSelectorTreeInstance, createMetricSelect } from './metrics-selector-tree.builder';
import { IMetricDefinition } from '../../lib/types';
import { ToggleModel } from '../../lib/model/model-toggle';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import { IListCustomHeaderOptions } from '../list-custom-header';
import { onSearchMetricsTree } from '../../lib/utils/utils-metrics-search';
// import { ShadowDomDirectiveWrapper } from '../../lib/angular/directive-shadow-dom';
// import MetricsSelectorTreeCSS from './metrics-selection-tree.module.scss';

export class MetricsSelectorPanelFilterViewModel {
    value: string;
    constructor(value = '') {
        this.value = value;
    }

    clear() {
        this.value = '';
    }
}

export class MetricsSelectorPanelViewModel extends ToggleModel {
    filter: MetricsSelectorPanelFilterViewModel;
    constructor() {
        super();
        this.filter = new MetricsSelectorPanelFilterViewModel('');
    }
}

export interface IMetricsSelectorModel {
    selected: (string | IMetricDefinition)[];
    available: IMetricDefinition[];
    options?: { addAllMetrics?: boolean };
    onChange: (metrics: IMetricDefinition[]) => void;
}

export interface IMetricsSelectorModelArguments {
    panel?: MetricsSelectorPanelViewModel;
    selected: (string | IMetricDefinition)[];
    available: IMetricDefinition[];
    options: { addAllMetrics?: boolean };
    onChange: (metrics: IMetricDefinition[]) => void;
}

export class MetricsSelectorModel implements IMetricsSelectorModel {
    public selected: string[];
    readonly available: IMetricDefinition[];
    readonly options: { addAllMetrics: boolean };
    readonly onChange: (metrics: IMetricDefinition[]) => void;
    readonly panel: MetricsSelectorPanelViewModel;

    constructor(props: IMetricsSelectorModelArguments) {
        this.panel = props.panel ?? new MetricsSelectorPanelViewModel();
        this.available = props.available;
        this.selected = this.setSelectedMetrics(props.selected);
        this.options = { addAllMetrics: false, ...props.options };
        this.onChange = props.onChange;
    }

    public setSelectedMetrics(selected: (string | IMetricDefinition)[]) {
        const fields = selected.map(x => (typeof x === 'string' ? x : x.field));
        this.selected = fields;
        return [...fields];
    }
}

interface MetricsSelectorTreeDirectiveScope extends angular.IScope {
    model: MetricsSelectorModel;
    listCustomHeaderOptions: IListCustomHeaderOptions;
}
export const MetricsSelectorTreeDirective = () => [
    '$filter',
    function MetricsSelectorTreeDirective(
        $filter: angular.IFilterService,
    ): angular.IDirective<MetricsSelectorTreeDirectiveScope> {
        // $compile: angular.ICompileService,
        // return ShadowDomDirectiveWrapper($compile, {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            // style: MetricsSelectorTreeCSS,
            template: `
                <article class="ui-metric-selector" ng-show="model.panel.isActive">
                    <header class="metric-filter-header">
                        <list-custom-header
                            model="model"
                            toggle="model.panel"
                            options="listCustomHeaderOptions"
                        ></list-custom-header>
                    </header>
                    <div class="metric-selector-tree"></div>
                </article>
            `,
            link: function MetricSelectorTreeLink(scope, element) {
                const $metricSelectElement = $(element).find('.metric-selector-tree');
                let metricSelect: null | IMetricSelectorTreeInstance = null;

                scope.listCustomHeaderOptions = {
                    itemLabel: 'metrics',
                    selectAll: () => {
                        if (!metricSelect) return;
                        const visibleNodes = metricSelect.getVisibleNodes();
                        if (visibleNodes.length === scope.model.available.length) {
                            metricSelect.selectAll();
                            onChangeSelectedMetrics();
                            return;
                        }

                        metricSelect.selectNodes(visibleNodes);
                        onChangeSelectedMetrics();
                    },
                    reset: () => {
                        metricSelect?.unselectAll();
                        onChangeSelectedMetrics();
                        scope.model.panel.filter.clear();
                    },
                    onSearch: (value: string) => {
                        metricSelect?.showJsTreeIfHidden();
                        metricSelect?.setSearch(value);
                    },
                };

                const onChangeSelectedMetrics = () => {
                    const metrics = metricSelect?.getSelected() ?? [];
                    if (_.isEqual(metrics, scope.model.selected)) return;
                    let selectedMetrics = scope.model.available.filter(x => metrics.includes(x.field));
                    selectedMetrics = _.sortBy(selectedMetrics, x => metrics.indexOf(x.field));
                    scope.model.onChange(selectedMetrics);
                };

                const reload = () => {
                    metricSelect?.destroy();
                    metricSelect = createMetricSelect({
                        element: $metricSelectElement,
                        model: _.cloneDeep({ selected: scope.model.selected, available: scope.model.available }),
                        options: scope.model.options,
                        onChange: () => onChangeSelectedMetrics(),
                        onSearch: onSearchMetricsTree,
                    });
                };

                scope.$watch('model.panel.isActive', (isActive: boolean) => {
                    if (isActive) {
                        reload();
                    } else {
                        scope.model.panel.filter.clear();
                    }
                });
                scope.$on('$destroy', () => metricSelect?.destroy());
            },
        };
    },
];

interface MetricsSelectorDirectiveScope extends angular.IScope {
    onMetricsExpandClick: ($event: Event) => void;
    metricSelectorTreeModel: undefined | MetricsSelectorModel;
    panel: MetricsSelectorPanelViewModel;
    model: undefined | IMetricsSelectorModel;
}

export const MetricsSelectorDirective = () => [
    'OutsideElementClick',
    function MetricsSelectorDirective(
        OutsideElementClick: IOutsideElementClick,
    ): angular.IDirective<MetricsSelectorDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="ui-metric-selector-tree-container">
                    <button class="metric-expand" ng-click="onMetricsExpandClick($event)" ng-class="{active:panel.isActive}">
                        <i class="icon-pencil"></i>
                        <span>Edit Metrics</span>
                    </button>
                    <metric-selector-tree ng-if="metricSelectorTreeModel" model="metricSelectorTreeModel"></metric-selector-tree>
                </article>
            `,
            link: function MetricSelectorLink(scope, $element) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const element = $element[0]!;

                const expandBtnEl = $element.find('.metric-expand')[0];
                if (!expandBtnEl) throw new Error("Couldn't find HTML element: .metric-expand");

                scope.onMetricsExpandClick = $event => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    scope.panel.toggle();
                };

                scope.$watch(
                    'model',
                    (model: MetricsSelectorDirectiveScope['model']) => {
                        scope.metricSelectorTreeModel = (() => {
                            if (!model) return;
                            const onChange = model.onChange;
                            return model instanceof MetricsSelectorModel
                                ? model
                                : new MetricsSelectorModel({
                                      selected: model.selected,
                                      available: model.available,
                                      options: { ...{ addAllMetrics: false }, ...(model.options ?? {}) },
                                      panel: scope.panel,
                                      onChange,
                                  });
                        })();

                        scope.panel = scope.metricSelectorTreeModel?.panel ?? new MetricsSelectorPanelViewModel();
                    },
                    true,
                );

                const getRelativeParentElement = (element: HTMLElement): HTMLElement | null => {
                    const parent = element.parentElement;
                    if (!parent) return null;
                    if (getComputedStyle(parent).position === 'relative') return parent;
                    return getRelativeParentElement(parent);
                };

                const updateMetricsTreeDropdownPosition = () => {
                    const uiMetricSelectorElement = $element.find('.ui-metric-selector')[0];
                    if (!uiMetricSelectorElement) return;

                    const relativeParentElement = getRelativeParentElement(uiMetricSelectorElement);
                    if (!relativeParentElement) return;

                    const relativeParentElementTop = relativeParentElement.getBoundingClientRect().top;
                    const metricSelectorTreeTop = expandBtnEl.getBoundingClientRect().bottom - relativeParentElementTop;

                    // Update the top position of the metrics tree dropdown
                    uiMetricSelectorElement.style.top = `${metricSelectorTreeTop}px`;
                };

                const resizeObserver = new ResizeObserver(updateMetricsTreeDropdownPosition);
                resizeObserver.observe(element);

                let elementClick: () => void = () => {};
                scope.$watch('panel.isActive', (isActive: boolean) => {
                    elementClick();
                    if (!isActive) return;
                    updateMetricsTreeDropdownPosition();
                    const expandEl = $element.find('.ui-metric-selector, .metric-expand');
                    elementClick = OutsideElementClick(scope, expandEl, () => scope.panel.close());
                });

                scope.$on('$destroy', () => resizeObserver.disconnect());
            },
        };
    },
];

const MetricsSelectorTreeModule = angular
    .module('42.components.metrics-selector-tree', [])
    .directive('metricSelectorTree', MetricsSelectorTreeDirective())
    .directive('uiMetricSelectorTree', MetricsSelectorDirective());

export default MetricsSelectorTreeModule;
