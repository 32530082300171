import _ from 'lodash';
import angular from 'angular';
import { IQuery, IQueryWhereTableFilter } from '../../../lib/types';
import { buildStatsViewModel, compileSubfilters } from '../customer.helper';
import { ICustomerCountService, ICustomerReportService, ICustomerStatsService } from '../customer.types';
import { ICustomer } from '../../../modules/services/query-service.types';
import { CustomerStatsItem } from './customer-stats.directive';
import { SimplePaginator } from './lib/simple-paginator';
import { FilterExpressionToQueryFilterFactory } from '../../../lib/parsers/filter-expression-to-query-filter';

const CustomersTableFilterQueryBuilder = FilterExpressionToQueryFilterFactory({
    name: 'string',
    email: 'string',
    total_spent: 'number',
    transaction_count: 'number',
    avg_spent: 'number',
    average_visit_distance: 'number',
});

export interface CustomersState {
    stats: CustomerStatsItem[];
    customers: ICustomer[];
    count?: number;
}

type ICustomerSortable = {
    model?: Record<string, number>;
    refreshSort?: () => void;
};
export type ICustomerSearch = Record<string, string>;

export interface IStatsState {
    stats: null | CustomerStatsItem[];
    init(): Promise<IStatsState>;
}

export interface ICustomerTable {
    customers: null | ICustomer[];
    paginator: SimplePaginator;
    init(returnToFirstPage: boolean): Promise<void>;
}

export const CustomerViewFactory = (
    $filter: angular.IFilterService,
    CustomerStats: ICustomerStatsService,
    CustomersReport: ICustomerReportService,
    CustomerCount: ICustomerCountService,
) => {
    class StatsState implements IStatsState {
        query: IQuery;
        stats: CustomerStatsItem[] | null = null;

        constructor({ query }: { query: IQuery }) {
            this.query = _.cloneDeep(query);
        }

        init() {
            this.stats = null;
            return CustomerStats.fetch(this.query).then(stats => {
                this.stats = buildStatsViewModel(stats, $filter);
                return this;
            });
        }
    }

    class CustomerReportState {
        query: IQuery;
        data: ICustomer[] | null = null;

        constructor({ query, paginator }: { query: IQuery; paginator: SimplePaginator }) {
            this.query = _.cloneDeep({ ...query, ...paginator.toQuery() });
            this.data = null;
        }

        init() {
            return CustomersReport.fetch(this.query).then(customers => {
                this.data = customers;
                return this;
            });
        }
    }

    class CustomerTable {
        query: IQuery;
        sortable: ICustomerSortable;
        paginator: SimplePaginator;
        customers: CustomerReportState | null;

        constructor({ query, sortable }: { query: IQuery; sortable?: ICustomerSortable }) {
            sortable ??= {};
            this.sortable = {
                model: { total_spent: -1 },
                ...sortable,
                refreshSort: () => this.refreshCustomers(),
            };
            this.query = _.cloneDeep(query);
            this.paginator = new SimplePaginator(50);
            this.customers = null;
        }

        init() {
            return this.refreshPaginator()
                .then(() => this.refreshCustomers())
                .then(() => this);
        }

        goToNextPage() {
            this.paginator.next();
            this.refreshCustomers();
        }

        goToPreviousPage() {
            this.paginator.prev();
            this.refreshCustomers();
        }

        refreshPaginator() {
            const query = this.toQuery();
            return CustomerCount.fetch(query).then(count => {
                this.paginator.setItemCount(count);
                return this.refreshCustomers();
            });
        }

        refreshCustomers() {
            const query = this.toQuery();
            this.customers = new CustomerReportState({ query, paginator: this.paginator });
            void this.customers.init();
        }

        toQuery(): IQuery {
            const sort = this.sortable.model ?? { total_spent: 1 };
            return _.cloneDeep({ ...this.query, ...{ sort } });
        }

        toExport() {
            return CustomersReport.export(this.toQuery());
        }
    }

    class CustomerView {
        query: IQuery | null;
        search: ICustomerSearch | null;
        stats: IStatsState | null;
        table: CustomerTable | null;

        constructor({ query }: { query?: IQuery } = {}) {
            this.search = null;
            this.stats = null;
            this.table = null;
            this.query = query ?? null;
        }

        setQuery(query: IQuery) {
            this.query = _.cloneDeep(query);
            this.refresh();
        }

        setSearch(customerSearch: ICustomerSearch) {
            this.search = _.cloneDeep(customerSearch);
            this.table?.paginator.setPage(1);
            this.refresh();
        }

        refresh() {
            this.refreshStats();
            this.refreshTable();
        }

        refreshStats() {
            const query = this.toQuery();
            this.stats = new StatsState({ query });
            void this.stats.init();
        }

        refreshTable() {
            const payload: { query: IQuery; sortable?: ICustomerSortable } = { query: this.toQuery() };

            if (this.table?.sortable) {
                payload.sortable = this.table.sortable;
            }

            this.table = new CustomerTable(payload);
            void this.table.init();
        }

        toQuery(): IQuery {
            const where = this.toQueryWhere();
            const query = _.cloneDeep({ ...this.query });
            delete query.where;
            if (!where) return query;
            return { ...query, where };
        }

        toQueryWhere(): null | IQueryWhereTableFilter {
            if (!this.search) return null;
            const filters = CustomersTableFilterQueryBuilder.parse(this.search);
            const where = compileSubfilters({ list: filters });
            // @ts-expect-error don't know how to fix this...
            return _.isEmpty(where) ? null : where;
        }

        toExport() {
            return this.table?.toExport();
        }
    }

    return CustomerView;
};
export type ICustomerView = InstanceType<ReturnType<typeof CustomerViewFactory>>;

export const CustomerControllerInstance = () => [
    '$scope',
    '$filter',
    '$rootScope',
    'CustomersReport',
    'promiseTracker',
    'CustomerCount',
    'CustomerStats',
    function CustomerController(
        $scope: angular.IScope & {
            state: {
                view: null | ICustomerView;
            };
            export: () => void;
            goToNextPage: () => void;
            goToPreviousPage: () => void;
            changedPaginatorNumber: () => void;
            customerExportTracker: angular.promisetracker.PromiseTracker;
        },
        $filter: angular.IFilterService,
        $rootScope: angular.IRootScopeService & { query: IQuery },
        CustomersReport: ICustomerReportService,
        promiseTracker: angular.promisetracker.PromiseTrackerService,
        CustomerCount: ICustomerCountService,
        CustomerStats: ICustomerStatsService,
    ) {
        const CustomerView = CustomerViewFactory($filter, CustomerStats, CustomersReport, CustomerCount);
        $scope.customerExportTracker = promiseTracker();

        $scope.export = () => {
            const promise = $scope.state.view?.toExport();
            if (!promise) return;
            $scope.customerExportTracker.addPromise(promise);
        };

        $scope.goToNextPage = () => {
            $scope.state.view?.table?.goToNextPage();
        };

        $scope.goToPreviousPage = () => {
            $scope.state.view?.table?.goToPreviousPage();
        };

        $scope.changedPaginatorNumber = _.debounce(() => {
            if (!$scope.state.view?.table) return;
            const value = $scope.state.view.table.paginator.page;
            const pages = $scope.state.view.table.paginator.pages ?? 0;
            if (_.isNumber(value) && value > 0 && value <= pages) {
                $scope.state.view.table.refreshCustomers();
            }
        }, 400);

        $scope.state = {
            view: null,
        };

        $scope.$on(
            '$destroy',
            $rootScope.$watch('initialized', (initialized: boolean) => {
                if (!initialized) return;
                $scope.state.view = new CustomerView();
                $scope.state.view.setQuery($rootScope.query);
                $scope.$on(
                    '$destroy',
                    $rootScope.$on('query.refresh', () => {
                        $scope.state.view?.setQuery($rootScope.query);
                    }),
                );
            }),
        );
    },
];
