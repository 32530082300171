import _ from 'lodash'
import * as Analytics from './analytics'
import { retry } from '@42technologies/retry'
import { MetricUtils } from '@42technologies/models-query'
import { QueryServiceAPI } from './api/api-query-service'
import { CurrenciesService, CurrencyModelService } from '../modules/currency/currency.service'
import { isObject, seconds, CustomError } from './utils'

###*
@typedef {Omit<import('./types').IMetricDefinition, 'headerGroup'> & {headerGroup?:string}} IMetricDefinitionOptionalHeaderGroup
@typedef {import('./types').IMetricDefinition} IMetricDefinition
@typedef {import('./types').IKpisOverrides} IKpisOverrides
@typedef {import('./types').IKpisCategoryOverrides} IKpisCategoryOverrides
@typedef {import('./types').IKpisDefinitions} IKpisDefinitions
@typedef {import('./config-metrics').IQueryMetricsOrgConfig} IQueryMetricsOrgConfig
@typedef {import('./config-metrics').IQueryMetricsUserConfig} IQueryMetricsUserConfig
@typedef {import('./config-metrics').IQueryMetricsConfig} IQueryMetricsConfig
###


###*
@param {IMetricDefinitionOptionalHeaderGroup[]} metrics
@returns {IMetricDefinition[]}
###
normalizeMetrics = (metrics) ->

    invalid = metrics.filter((x) -> typeof x.headerGroup isnt 'string')
    if invalid.length isnt 0
        console.group("[config-metrics][normalizeMetrics] Metrics with missing headerGroup:")
        invalid.forEach (x) -> console.warn(x)
        console.groupEnd()
        Analytics.logError(new Error("Metrics with missing headerGroup: #{invalid.map((x) -> x.field).join(', ')}"))

    ###* @type {(x: IMetricDefinitionOptionalHeaderGroup) => x is IMetricDefinition} ###
    normalizeFn = (x) -> typeof x.headerGroup is 'string'
    return normalizeMetricCurrencyTemplate(metrics.filter(normalizeFn))


###*
@param {string} label
@returns {string}
###
normalizeLabel = (label) ->
    return label.replace(/\[\s*currency\s*\]/ig, '{{ currency }}')


###* @type {<T extends {headerGroup?: string, headerName?: string}>(metrics: T[]) => T[]} ###
normalizeMetricCurrencyTemplate = (metrics) ->
    metrics = _.cloneDeep(metrics)
    metrics.forEach (metric) ->
        metric.headerGroup = normalizeLabel(metric.headerGroup) if "headerGroup" of metric and typeof metric.headerGroup is 'string'
        metric.headerName = normalizeLabel(metric.headerName) if "headerName" of metric and typeof metric.headerName is 'string'
    return metrics


###* @type {import('./config-metrics').IQueryMetrics['applyCurrencyToMetrics']} ###
applyCurrencyToMetrics = (metrics, currency) ->
    symbol = currency?.symbol ? '$'
    metrics = _.cloneDeep(metrics)
    metrics.forEach (metric) ->
        metric.headerGroup = template(normalizeLabel(metric.headerGroup), {currency:symbol}) if "headerGroup" of metric and typeof metric.headerGroup is 'string'
        metric.headerName = template(normalizeLabel(metric.headerName), {currency:symbol}) if "headerName" of metric and typeof metric.headerName is 'string'
    return metrics


###*
@param {IMetricDefinitionOptionalHeaderGroup[]} metrics
@param {IKpisCategoryOverrides} [categoryOverrides]
###
applyCategoryOverridesToMetrics = (metrics, categoryOverrides) ->
    return applyOverridesToMetrics metrics, do ->
        return {} if not _.isObject(categoryOverrides)
        metrics = _.cloneDeep(metrics)
        metricsByCategory = _.groupBy(metrics.filter((x) -> Boolean(x.category)), (x) -> x.category)
        overrides = Object.entries(categoryOverrides).flatMap ([category, categoryOverride]) ->
            categoryMetrics = metricsByCategory[category] ? []
            if categoryMetrics.length is 0
                console.warn("Can't override metric category, the category does not exist:", category)
            return categoryMetrics.map (x) ->
                return {..._.cloneDeep(categoryOverride), field: x.field}
        return Object.fromEntries(overrides.map((x) -> [x.field, _.omit(x, 'field')]))


###*
@param {IMetricDefinitionOptionalHeaderGroup[]} metrics
@param {IKpisCategoryOverrides} [overrides]
@returns {IMetricDefinitionOptionalHeaderGroup[]} metrics
###
applyOverridesToMetrics = (metrics, overrides) ->
    metrics = _.cloneDeep(metrics)
    metricsByField = _.keyBy(metrics, (x) -> x.field)

    overrides = do ->
        result = _.cloneDeep(overrides or {})
        return {} if not isObject(result)
        return result

    for field from Object.keys(overrides)
        override = overrides[field]
        if not isObject(override)
            console.warn("Metric override `#{field}` is not an object:", override)
            continue
        metric = metricsByField[field]
        if not metric
            # console.warn "Metric override `#{field}` has no matching metric."
            # console.warn override
            continue
        for key in Object.keys(_.omit(override, 'field'))
            override[key] = template(override[key], metric)
        _.extend(metric, override)

    return metrics


###*
@param {string} str
@param {Record<string, any>} locals
@returns {string}
###
template = (str, locals) ->
    templateOptions = {interpolate:/{{([\s\S]+?)}}/g}
    return _.template(str, templateOptions)(locals)


###* @param {IQueryMetricsOrgConfig} orgConfig ###
getCustomMetricFilters = (orgConfig) ->
    return orgConfig.kpis?.filters ? {}


###*
@typedef {Omit<IKpisDefinitions[keyof IKpisDefinitions], 'field'> & {field:string}} IKpiDefinitionNormalized
@param {IQueryMetricsOrgConfig} orgConfig
@returns {IKpiDefinitionNormalized[]}
###
getCustomMetricDefinitions = (orgConfig) ->

    ###* @param {undefined | import('./types').IKpisDefinitions} definitions ###
    expandShorthandDefinitions = (definitions) ->
        return {} if not definitions
        expanded = Object.fromEntries Object.entries(definitions).flatMap ([id, definition]) ->
            return [[id, definition]] if not MetricUtils.isMetricIdWithShorthandNotation(id)
            return MetricUtils.expandFromShorthandNotation(id).map((child) -> [child, definition])
        return { ...expanded, ...definitions }

    result = do ->
        return expandShorthandDefinitions(orgConfig['metrics:v1']?.definitions) if orgConfig['metrics:v1']?.definitions
        return orgConfig.kpis?.definitions ? {}

    return Object.keys(result).map (id) ->
        return {...result[id], field: id}


###*
@typedef {import('./types').IKpiFoundationItem} IKpiFoundationItem
@typedef {Omit<import('./types').IKpiFoundationItem, 'field' | 'headerGroup'> & {field: string, headerGroup: string}} IKpiFoundationItemNormalized
@param {IQueryMetricsOrgConfig} orgConfig
@returns {Record<string, IKpiFoundationItemNormalized>}
###
getCustomFoundationMetricDefinitions = (orgConfig) ->
    foundations = orgConfig.kpis?.foundations ? {}

    ###* @type {[id: string, metric:IKpiFoundationItem][]} ###
    invalid = []

    ###* @type {Record<string, IKpiFoundationItemNormalized>} ###
    result = {}
    for foundation from Object.values(foundations)
        continue if not isObject(foundation)
        for [field, definition] from Object.entries(foundation)
            {headerGroup, ...definition} = definition
            if typeof headerGroup isnt 'string'
                invalid.push([field, definition])
                continue
            result[field] = {...definition, headerGroup, field}

    if invalid.length > 0
        console.group("[config-metrics][getCustomFoundationMetricDefinitions] Foundation metric defs missing headerGroup:")
        invalid.forEach ([field, definition]) -> console.warn({...definition, field})
        console.groupEnd()
        error = new Error("Missing header group for foundation metric definitions: #{invalid.map(([field]) -> field).join(', ')}")
        Analytics.logError(error)

    return result


class ConfigMetricsError extends CustomError
class ConfigMetricsFetchDefaultMetricsError extends ConfigMetricsError


###*
@returns {Promise<import('./api/api-query-service').QueryServiceMetric[]>}
###
fetchDefaultMetrics = ->
    return await retry((() ->
        api = await QueryServiceAPI.get()
        metrics = await api.organizations.getMetrics({})
        throw new ConfigMetricsFetchDefaultMetricsError("invalid query service metrics response; not an array (#{typeof metrics})") if not Array.isArray(metrics)
        throw new ConfigMetricsFetchDefaultMetricsError("invalid query service metrics response; array is empty") if metrics.length is 0
        return metrics
    ), {logger: console, delay: { max: seconds(3) }})


###*
@param {IQueryMetricsConfig} config
###
fetchStandardMetrics = (config) ->
    standardMetrics = await fetchDefaultMetrics()
    customFoundationMetrics = getCustomFoundationMetricDefinitions(config.organization)
    return Object.values({
        ..._.keyBy(standardMetrics, 'field'),
        ..._.keyBy(customFoundationMetrics, 'field')
    })


###*
@param {(IMetricDefinition | import('./api/api-query-service').QueryServiceMetric)[]} metrics
@param {import('./types').IKpiItem[]} definitions
@param {import('./types').IKpisFilters} metricFilters
@returns {IMetricDefinition[]} definitions
###
convertMetricFiltersToDefinitions = (metrics, definitions, metricFilters) ->
    metricsByField = _.keyBy(metrics, 'field')
    definitionsByField = _.keyBy(definitions, 'field')
    errors = []
    result = Object.entries(metricFilters).flatMap ([prefix, metricFilter]) -> metricFilter.metrics.flatMap (metricId) ->
        field = "#{prefix}_#{metricId}"
        if definitionsByField[field]
            errors.push(['Filtered metric', field, 'already defined.'])
            return []
        metric = metricsByField[metricId]
        if not metric
            errors.push(['Filtered metric', field, 'is missing definition for', metricId])
            return []
        if not metricFilter.label
            errors.push(['Filtered metric', field, 'is missing label.'])
            return []
        return [{
            ..._.cloneDeep(metric),
            headerGroup: "#{metricFilter.label} #{metric.headerGroup}",
            fields: [field],
            field: field,
            query: field,
        }]
    if errors.length isnt 0
        console.group("[config-metrics][convertMetricFiltersToDefinitions] Errors:")
        errors.forEach (error) -> console.warn(...error)
        console.groupEnd()
    return result


###*
@param {IQueryMetricsConfig} config
@returns {Promise<IMetricDefinitionOptionalHeaderGroup[]>}
###
fetchAvailableMetrics = (config) ->
    metrics = await fetchStandardMetrics(config)
    definitions = getCustomMetricDefinitions(config.organization)
    metricFilters = getCustomMetricFilters(config.organization)
    definitionFilters = convertMetricFiltersToDefinitions(metrics, definitions, metricFilters)
    return [...metrics, ...definitions, ...definitionFilters]


###*
@param {IQueryMetricsConfig} config
@returns {null | string[]}
###
fetchSelectedMetrics = ({ user: userConfig, organization: orgConfig }) ->

    ###*
    @param {unknown} metrics
    @returns {null | string[]}
    ###
    normalizeMetricsArray = (metrics) ->
        return null if not Array.isArray(metrics)
        return _.uniq metrics.flatMap (metricId) ->
            return [] if typeof metricId isnt 'string'
            return [] if metricId.trim().length is 0
            return [metricId]

    ###*
    @param {unknown} selected
    @param {null | string[]} available
    ###
    normalizeMetricsWithObject = (selected, available) ->
        return normalizeMetricsArray(selected) if Array.isArray(selected)
        return null if not isObject(selected)
        throw new Error("No org metrics configured, can't patch!") if not available
        additions = normalizeMetricsArray(selected.add) ? []
        deletions = normalizeMetricsArray(selected.del) ? []
        return _.uniq([...available, ...additions].filter((id) -> not deletions.includes(id)))

    ###* @param {unknown} metrics ###
    normalizeAndExpandMetrics = (metrics) ->
        normalized = normalizeMetricsArray(metrics)
        return null if not Array.isArray(normalized)
        return _.uniq normalized.flatMap (metricId) ->
            try return MetricUtils.expandFromShorthandNotation(metricId)
            catch error
                console.error("[config-metrics] invalid metric ID:", metricId, error)
                return []

    ###*
    @param {unknown} selected
    @param {null | string[]} available
    ###
    normalizeAndExpandMetricsWithObject = (selected, available) ->
        return normalizeAndExpandMetrics(selected) if Array.isArray(selected)
        return null if not isObject(selected)
        throw new Error("No org metrics configured, can't patch!") if not available
        additions = normalizeAndExpandMetrics(selected.add) ? []
        deletions = normalizeAndExpandMetrics(selected.del) ? []
        return _.uniq([...available, ...additions].filter((id) -> not deletions.includes(id)))

    org = do ->
        return normalizeAndExpandMetrics(orgConfig['metrics:v1']?['kpis']) if orgConfig['metrics:v1']?['kpis']
        return normalizeMetricsArray(orgConfig.views?.metrics?.kpis)

    user = do ->
        return normalizeAndExpandMetricsWithObject(userConfig.accessControl?['metrics:v1']?.kpis, org) if userConfig.accessControl?['metrics:v1']?.kpis
        return normalizeMetricsWithObject(userConfig.accessControl?['kpis'], org)

    return user ? org ? null


###*
@param {IQueryMetricsConfig} config
@returns {IKpisCategoryOverrides} categoryOverrides
###
getKpisCategoryOverrides = ({ user, organization }) ->
    ###* @ts-expect-error TODO ###
    return user.kpis?.categoryOverrides ? organization.kpis?.categoryOverrides ? {}


###*
@param {IQueryMetricsConfig} config
@returns {IKpisOverrides} overrides
###
getKpisOverrides = ({ user, organization }) ->
    ###* @ts-expect-error TODO ###
    return user.kpis?.overrides ? organization.kpis?.overrides ? {}


###* @type {import('./config-metrics').IQueryMetricsFactory} ###
export QueryMetrics = (dependencies) ->

    ###* @type {null | Promise<IMetricDefinition[]>} ###
    metricsCache = null
    currenciesCache = null
    dependencies = _.cloneDeep(dependencies)

    ###*
    @param {IMetricDefinitionOptionalHeaderGroup[]} available
    @param {null | string[]} selected
    @returns {IMetricDefinitionOptionalHeaderGroup[]}
    ###
    resolveSelectedMetrics = (available, selected) ->
        available = available.filter (x) -> typeof x.field is 'string'
        metricsByField = _.keyBy(available, (x) -> x.field)
        return available if not selected
        ###* @type {IMetricDefinitionOptionalHeaderGroup[]} ###
        result = []
        for metricId from selected
            metric = metricsByField[metricId]
            continue if not metric
            result.push(metric)
        return result

    ###*
    Temporary fix until the customer page is fully connected to the config of an org.
    The added metrics do not show up in the regular reports. They are only used for the query service.
    @param {string[]} selected
    ###
    addMissingCustomerPageMetrics = (selected) ->
        return _.uniq([
            ...selected,
            "demand_net_sales",
            "demand_transaction_count",
            "demand_dollar_per_transaction",
            "demand_latest_order_timestamp"
        ])

    fetchConfig = ->
        return dependencies.config if dependencies and dependencies.config
        ConfigAPI = dependencies?.ConfigAPI ? (await import("./config-api")).ConfigAPI
        api = await ConfigAPI.get()
        [user, organization] = await Promise.all([api.user.getInternal(), api.organization.get()])
        return {user, organization}

    fetchMetrics = ->
        config = await fetchConfig()
        [available, selected] = await Promise.all([
            fetchAvailableMetrics(config)
            fetchSelectedMetrics(config)
        ])
        selected = addMissingCustomerPageMetrics(selected) if Array.isArray(selected)
        metrics = resolveSelectedMetrics(available, selected)

        console.groupCollapsed("[config-metrics] Resolved Metrics")
        metrics.forEach (x) -> console.log(x)
        console.groupEnd()

        metrics = do ->
            overrides = getKpisCategoryOverrides(config)
            return applyCategoryOverridesToMetrics(metrics, overrides)

        metrics = do ->
            overrides = getKpisOverrides(config)
            return applyOverridesToMetrics(metrics, overrides)

        return normalizeMetrics(metrics)

    getMetrics = ->
        metricsCache ?= fetchMetrics()
        return metricsCache.then((x) -> _.cloneDeep(x))

    getCurrenciesById = ->
        currenciesCache ?= CurrenciesService.fetch().then (currencies) -> _.keyBy(currencies, 'id')
        return currenciesCache.then((x) -> _.cloneDeep(x))

    ###* @param {undefined | string | {id:string}} [currency] ###
    resolveCurrency = (currency) ->
        selected = await do ->
            return currency if isObject(currency)
            return {id: currency} if typeof currency is 'string'
            return CurrencyModelService.fetch().then((x) -> x.selected)
        available = await getCurrenciesById()
        resolved = available[selected.id.toLowerCase()]
        throw new Error("Currency not found: #{selected.id}") if not resolved
        return _.cloneDeep(resolved)

    return {
        applyCurrencyToMetrics: applyCurrencyToMetrics
        fetch: (currency) ->
            return applyCurrencyToMetrics(...await Promise.all([getMetrics(), resolveCurrency(currency)]))
    }
