import _ from 'lodash';

export const IncludesFilter = {
    parse: (text: unknown, value: string, caseSensitive?: boolean, excludePatterns?: string[]) => {
        if (_.isNil(text)) return [];
        const data = Array.isArray(text) ? text : [text];
        if (_.isNil(value) || value === '') return data;
        value = caseSensitive ? value : value.toLowerCase();
        return data.filter(x => {
            const dataValue = caseSensitive ? String(x) : String(x).toLowerCase();

            if (!excludePatterns || excludePatterns.length === 0) {
                return dataValue.includes(value);
            }

            const patternsToExclude = caseSensitive ? excludePatterns : excludePatterns.map(x => x.toLowerCase());
            return dataValue.includes(value) && !patternsToExclude.some(p => p === dataValue);
        });
    },
};

export const IncludesFilterFactory = () => [
    function IncludesFilterFn() {
        return IncludesFilter.parse;
    },
];

export default angular.module('42.filters.includes', []).filter('includes', IncludesFilterFactory());
