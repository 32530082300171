import angular from 'angular'
import 'angular-promise-tracker'
import _ from 'lodash'
import * as ConfigExperimentsAPI from '../../lib/config-experiments'
import * as Analytics from '../../lib/analytics'
import * as AuthServiceAPI from '../../lib/auth'
import Utils from '../../lib/utils'
import { downloadFile } from '../../lib/services/download-file'
import { ToggleModel } from '../../lib/model/model-toggle'
import { GridItemCellRendererFactory, GridInfoCellRendererFactory } from './components/grid-cell-renderer'
import { GridOverlayMessageRendererFactory } from '../../components/grid-overlay-message-renderer'
import { SidebarModel } from '../../components/sidebar'
import { ActionsPanelSelectItem, ActionsPanelButtonItem, ActionsPanelModel, ActionsPanelSimpleItem, ActionsCustomMetricTreeModel} from '../../components/actions-panel'
import { isObject } from '../../lib/utils'
import { getOrganization } from '../../lib/auth'
import { GridPageViewDataSchema, GridPageViewMetaSchema } from './item-action-model-state-validation'
import { QueryServiceExport } from '../../modules/services/query-service-export'
import { getNumberOfPagesEstimate, ITEM_EXPORT_OPTIONS } from './grid-page-export-page-count-estimator'
import { ItemGridDataViewModel } from './item-grid-data-view-model'

###*
@typedef {import('../../components/drag-and-drop').DragAndDropExternalAPI} DragAndDropExternalAPI
@typedef {import('../../lib/types').IConfigObj} IConfigObj
@typedef {import('../../lib/types').IMetricDefinition} IMetricDefinition
@typedef {import('../../lib/config-hierarchy').IPropertyDefinition} IPropertyDefinition
@typedef {import('../../modules/hierarchy/hierarchy.module').IHierarchyService} IHierarchyService
@typedef {import('../main-controller').IHourPropertyService} IHourPropertyService
@typedef {import('../main-controller').IQueryMetrics} IQueryMetrics
@typedef {import('../main-controller').DashboardRootScope} DashboardRootScope
@typedef {import('../../directives/outside-element-click.directive').IOutsideElementClick} IOutsideElementClick
@typedef {import('./components/grid-cell-renderer').GridActionsModel} GridActionsModel
@typedef {InstanceType<GridActionsModel>} IGridActionsModel
@typedef {import('./components/grid-cell-renderer').IGridPageCellRenderer} IGridPageCellRenderer
@typedef {import('../../lib/angular').AngularInjected<typeof ItemGridModel>} _ItemGridModel
@typedef {ReturnType<_ItemGridModel>} IItemGridModel
@typedef {import('../../lib/angular').AngularInjected<typeof _ItemActionModelList>} ItemActionModelList
@typedef {InstanceType<ItemActionModelList>} IItemActionModelList
@typedef {import('../../lib/angular').AngularInjected<typeof _ItemActionsModelState>} ItemActionsModelStateService
###

module = angular.module '42.controllers.items', []
module.config ($routeProvider, ROUTES, CONFIG) ->
    override = _.pick(CONFIG.routes?.grid or CONFIG.routes?.items or {}, 'label', 'url')
    route = {...ROUTES.grid, ...override}
    $routeProvider.when(route.oldUrl, {redirectTo:route.url}) if route.oldUrl
    $routeProvider.when(route.url, route)


module.controller 'ItemsController', ['$q','$rootScope','promiseTracker','ItemViewState','$scope'
###*
@typedef {unknown} ItemViewStateActionModel
@param {angular.IQService} $q
@param {DashboardRootScope} $rootScope
@param {angular.promisetracker.PromiseTrackerService} promiseTracker
@param {{fetch: () => angular.IPromise<{actionsModel: ItemViewStateActionModel}>}} ItemViewState
@param {angular.IScope & {
    itemViewModel: unknown;
    viewItemsPromiseTracker: angular.promisetracker.PromiseTracker;
    state: {model: null | ItemViewStateActionModel};
    loaded: boolean;
    organizationId: string;
    experiments?: unknown;
}} $scope
###
($q, $rootScope, promiseTracker, ItemViewState, $scope) ->
    $scope.itemViewModel = {}
    $scope.loaded = true

    ###* @type {null | angular.IDeferred<void>} ###
    deferred = $q.defer()
    $scope.viewItemsPromiseTracker = promiseTracker()
    $scope.viewItemsPromiseTracker.addPromise(deferred.promise)
    $scope.state = {model: null}

    ###* @type {(() => void)[]} ###
    watchers = []
    ###* @returns {void} ###
    init = ->
        watchers.forEach((x) -> x())
        watchers = []
        watchers.push($rootScope.$on 'query.refresh', -> init())
        $scope.state.model = null
        promise = $q.all([
            ItemViewState.fetch()
            getOrganization()
            ConfigExperimentsAPI.fetch()
        ]).then ([{actionsModel}, organizationId, experiments]) ->
            deferred?.resolve()
            deferred = null
            console.log("[grid] updating actions model:", actionsModel)
            $scope.state.model = actionsModel
            $scope.organizationId = organizationId
            # TODO:
            # - Used as Toggle for `metricsCategorization` feature.
            $scope.experiments = experiments
        .catch (error) ->
            console.error("Items page state fetch error:", error)
            deferred?.reject(error)
        $scope.viewItemsPromiseTracker.addPromise(promise)
        return

    cleanupInitializedWatcher = $rootScope.$watch 'initialized', (initialized) ->
        return if not initialized
        return init()

    $scope.$on '$destroy', ->
        cleanupInitializedWatcher()
        watchers.forEach (x) -> x()
]

module.service 'ItemGridQuery', ['QueryServiceAPI', (QueryServiceAPI) ->
    fetch: (query) ->
        query = _.cloneDeep(query or {})
        throw new Error("Missing required `query.options.groupBy` property.") if not query.options?.groupBy
        return QueryServiceAPI().then (api) -> api.query.topItems(query)
]


METRICS_BLACK_LIST = ['item_image']

_ItemActionsModelState = ['$q','CONFIG','ItemActionModelStateAPI','Hierarchy','HourProperty','QueryMetrics',
###*
@param {angular.IQService} $q
@param {IConfigObj} CONFIG
@param {any} ItemActionModelStateAPI
@param {IHierarchyService} Hierarchy
@param {IHourPropertyService} HourProperty
@param {IQueryMetrics} QueryMetrics
###
($q, CONFIG, ItemActionModelStateAPI, Hierarchy, HourProperty, QueryMetrics) ->

    fetchState: () ->
        ItemActionModelStateAPI.get()
        .then (state) ->
            return state if _.isArray(state?.available)
            return {selected:state?.id, available:[state]}
        .then (state) =>
            state.available = _.compact(state.available)
            return state if state.available.length > 0
            return @createState().then (x) ->
                if state.available.length is 0
                    state.available.push(x)
                else
                    state.available.unshift(x)
                    state.selected = state.available[0]
                return state
        .catch (error) ->
            # FIXME: Dangerous! Could clear all views...
            console.error("Could not load item action model state:")
            console.error(error)
            Analytics.logError(error)
            return null

    fetchHierarchy: ->
        $q.all([
            Hierarchy.fetch()
            HourProperty.fetch()
        ]).then ([hierarchy, hourProperty]) ->
            hierarchy.groupBy.push(hourProperty) if hourProperty
            return hierarchy

    fetchMetrics: -> QueryMetrics.fetch().then (metrics) ->
        throw new Error("[grid] QueryMetrics.fetch() did not return an array") if not Array.isArray(metrics)
        throw new Error("[grid] QueryMetrics.fetch() returned an empty array") if metrics.length is 0
        return metrics.reduce(((acc, metric) ->
            return acc if METRICS_BLACK_LIST.includes(metric.field)

            # Frye Request: We round up all percentage cells.
            if metric.cellFilter and metric.cellFilter.indexOf('percent:') is 0 and metric.cellFilter.split(':').length is 3
                metric.cellFilter = "#{metric.cellFilter}:0"

            acc.push(metric)
            return acc
        ), [])

    ###* @type {null | angular.IPromise<string>} ###
    getDefaultMetricsPromise: null
    getDefaultMetrics: () ->
        DEFAULT_METRICS = [
            "demand_gross_sales",
            "demand_net_sales",
            "demand_gross_markdown_value",
            "demand_gross_sales_margin",
            "on_hands_units",
            "demand_sellthru_percentage"
        ]
        return @getDefaultMetricsPromise ?= $q.when do =>
            return CONFIG.defaults?.items?.metrics if CONFIG.defaults?.items?.metrics
            return @fetchMetrics().then (metrics) ->
                metricsByField = _.keyBy(metrics, 'field')
                demandMetricsToValidate = DEFAULT_METRICS.slice(0, 2)
                isDemandMetricsValid = demandMetricsToValidate.every((field) -> Boolean(metricsByField[field]))
                return DEFAULT_METRICS if isDemandMetricsValid
                return DEFAULT_METRICS.map((x) -> x.replace(/^demand_/, ''))

    fetchValues: -> $q.all([@fetchHierarchy(), @fetchMetrics()]).then ([{groupBy}, metrics]) ->

        hierarchy =
            groupBy      : groupBy.map((x) -> {...x, group: x.category?.label ? 'Uncategorized'})
            itemsGroupBy : groupBy.map((x) -> {...x, group: x.category?.label ? 'Uncategorized'})

        hierarchyIndex = Object.keys(hierarchy).reduce ((result, key) ->
            result[key] = {}
            hierarchy[key].forEach((x) -> result[key][x.id] = x)
            return result
        ), {}

        itemsSortBy = _.compact metrics.map (metric) ->
            return if metric.field.startsWith('growth')
            return if not metric.headerGroup
            id:    metric.field
            group: metric.category ? 'Uncategorized'
            label: do ->
                label = _.compact([metric.headerGroup, metric.headerName]).join(" ")
                return (label or "").trim()

        metrics: metrics
        metricsByField: _.keyBy(metrics, 'field')
        hierarchyIndex: hierarchyIndex
        groupBy: hierarchy.groupBy
        itemsGroupBy: hierarchy.itemsGroupBy
        itemsLimitBy: [5, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250]
        itemsSortBy: itemsSortBy
        itemsSortOrder: [
            { id:  1, label: 'ASC' }
            { id: -1, label: 'DESC' }
        ]

    ###* @param {{selected: IGridActionsModel, available: IGridActionsModel[]}} model ###
    save: (model) ->
        console.log("[grid] saving state...")
        states = @serializeStates(model)
        return ItemActionModelStateAPI.put(states)

    ###* @param {{selected: IGridActionsModel, available: IGridActionsModel[]}} model ###
    serializeStates: (model) ->
        selected:  model.selected?.id
        available: model.available.map (x) => @serializeState(x)

    ###* @param {IGridActionsModel} model ###
    serializeState: (model) ->
        views =
            panel: model.views.panel.serialize()['isOpen']
            images: model.views.images.serialize()['isOpen']
        selected =
            metrics:         model.selected.metrics
            groupBy:         model.selected.groupBy.id
            itemsGroupBy:    model.selected.itemsGroupBy.id
            itemsSortBy:     model.selected.itemsSortBy.id
            itemsLimitBy:    model.selected.itemsLimitBy
            itemsSortOrder:  model.selected.itemsSortOrder
            itemsThemeLarge: model.selected.itemsThemeLarge
        return {id:model.id, name:model.name, views, selected}

    fetch: () -> $q.all([@fetchValues(), @fetchState(), @getDefaultMetrics()]).then ([values, state, defaultMetrics]) =>
        state.available = state.available.map (x) => @normalizeState(values, x, defaultMetrics)
        state.selected = _.find state.available, (x) -> x.id is state.selected
        state.selected ?= state.available[0]
        return state

    createState: (state) -> $q.all([@fetchValues(), @getDefaultMetrics()]).then ([values, defaultMetrics]) =>
        return @normalizeState(values, state ? {}, defaultMetrics)

    duplicate: (item) ->
        $q.all([@fetchValues(), @getDefaultMetrics()]).then ([values, defaultMetrics]) =>
            newItem = @serializeState(item)
            newItem.id = undefined
            return @normalizeState(values, newItem, defaultMetrics)

    normalizeState: (values, state, defaultMetrics) ->
        state = {selected:state} if not state?.views

        state ?= {}
        state.selected ?= {}
        state.views ?= {}
        delete state.views.metrics

        ignoreError = (fn) ->
            try return fn()
            catch error
                try Analytics.logError(error)
                return null

        id: state.id or Utils.uuid()
        name: state.name or "New View"
        views:
            panel: ToggleModel.Parse(state.views.panel)
            images: ToggleModel.Parse(state.views.images)
        values: values
        selected:
            metrics: ignoreError ->
                state.selected.metrics ?= defaultMetrics
                state.selected.metrics = (state.selected.metrics or []).map (x) -> x.field or x
                availableMetrics = values.metrics.map (x) -> x.field
                return state.selected.metrics.filter (x) -> availableMetrics.includes(x)
            groupBy: ignoreError ->
                state.selected.groupBy ?= CONFIG.defaults?.items?.groupBy
                state.selected.groupBy = state.selected.groupBy?.id or state.selected.groupBy
                return values.groupBy[0] if not state.selected.groupBy
                return values.hierarchyIndex.groupBy[state.selected.groupBy]
            itemsGroupBy: ignoreError ->
                state.selected.itemsGroupBy ?= CONFIG.defaults?.items?.itemsGroupBy
                state.selected.itemsGroupBy = state.selected.itemsGroupBy?.id or state.selected.itemsGroupBy
                return values.hierarchyIndex.itemsGroupBy[state.selected.itemsGroupBy]
            itemsSortBy: ignoreError ->
                state.selected.itemsSortBy ?= CONFIG.defaults?.items?.itemsSortBy
                state.selected.itemsSortBy = state.selected.itemsSortBy?.id or state.selected.itemsSortBy
                return _.find values.itemsSortBy, (x) -> state.selected.itemsSortBy is x.id
            itemsLimitBy: ignoreError ->
                state.selected.itemsLimitBy ?= CONFIG.defaults?.items?.itemsLimitBy
                return state.selected.itemsLimitBy if values.itemsLimitBy.includes(state.selected.itemsLimitBy)
                return 10
            itemsThemeLarge: ignoreError ->
                state.selected.itemsThemeLarge ?= CONFIG.defaults?.items?.itemsThemeLarge ? null
                return state.selected.itemsThemeLarge
            itemsSortOrder: ignoreError ->
                value = state.selected.itemsSortOrder
                return value if [-1, 1].includes(parseInt(value))
                return -1
]
module.service('ItemActionsModelState', _ItemActionsModelState)



module.service 'ItemActionModelStateAPI', (Utils, StorageAPI) ->

    getKey = -> AuthServiceAPI.getOrganization().then (organization) ->
        prefix = "items.action-model-state"
         # HACK: We need to migrate user configs of all orgs.. doing it for Ippolita Wholesale in the meantime
         #       since they are complaining about hierarchy overwriting their views.
        return "#{prefix}-v2" if not _.startsWith(organization, 'ippolita_wholesale')
        return "#{prefix}-v3"

    getStorageAPI = ->
        return getKey().then((key) -> StorageAPI(key))

    get: ->
        return getStorageAPI().then (api) -> api.get()

    put: _.debounce(((data) ->
        try
            data = Utils.copy(data)
            api = await getStorageAPI()
            await api.put(data)
            return
        catch error
            error = new Error("[grid][save] Could not save item action model state...\n#{error?.message}")
            Analytics.logError(error)
            return
    ), 600)

module.service 'ItemViewState', ['ItemActionModelList', 'ItemActionsModelState',
###*
@param {ItemActionModelList} ItemActionModelList
@param {ItemActionsModelStateService} ItemActionsModelState
###
(ItemActionModelList, ItemActionsModelState) ->
    fetch: -> ItemActionsModelState.fetch().then((x) -> {actionsModel: new ItemActionModelList(x)})
]

module.directive 'itemView', ['ItemActionModelList',
###*
@param {ItemActionModelList} ItemActionModelList
@returns {angular.IDirective<angular.IScope & DragAndDropExternalAPI & {
    model: IItemActionModelList;
    experiments: Record<string, undefined | boolean>;
    organizationId: string;
    addNewView: () => void;
    removeTab: (tabId: string) => void;
    exportTab: IItemActionModelList['exportTab'];
    openTabImportPopup: (event: Event) => boolean;
}>}
###
(ItemActionModelList) ->
    restrict: 'E'
    scope:
        model: '='
        experiments: '='
        organizationId: '='
    replace: true
    template: \
    """
    <article class="view view-items">
        <header>
            <tabs-with-menu
                tabs="model.available"
                selected="model.selected"
                added="addNewView"
                removed="model.remove"
                dragged="model.reorder"
                duplicated="model.duplicate"
                shared="exportTab"
                imported="openTabImportPopup"
            >
            </tabs-with-menu>
        </header>
        <main class="view-items-body" drag-and-drop-zone>
            <item-view-container model="model" experiments="experiments"></item-view-container>
        </main>
    </article>
    """
    link: (scope) ->
        scope.addNewView = ->
            scope.model.add()
            Analytics.track(Analytics.EVENTS.USER_CREATE_VIEW_GRID)

        scope.removeTab = (tabId )->
            scope.model.remove(tabId) if window.confirm("""
                Are you sure you want to delete the View - "#{scope.model.selected.name}" ?\nThis action cannot be un-done.
            """)

        dnd = scope.fillDragAndDropZoneExternalAPI
            onFile: (file) ->
                data = ItemActionModelList.ValidateViewConfigFile(file, scope.organizationId)
                scope.model.createTabFromJSON(data)
                Analytics.track(Analytics.EVENTS.USER_IMPORT_GRID_TAB, {view: data})
            onError: (error) ->
                Analytics.track(Analytics.EVENTS.USER_IMPORT_GRID_TAB_FAILED, {error})

        scope.exportTab = (...args) ->
            scope.model.exportTab(...args)

        scope.openTabImportPopup = ($event) ->
            $event.preventDefault()
            $event.stopImmediatePropagation()
            dnd.openUploadPopup()
            return true
]



module.directive 'itemViewContainer', ['$q','$timeout','ItemGridQuery','OutsideElementClick',
###*
@param {angular.IQService} $q
@param {angular.ITimeoutService} $timeout
@param {{fetch: (query: unknown) => angular.IPromise<unknown>}} ItemGridQuery
@param {IOutsideElementClick} OutsideElementClick
@returns {angular.IDirective<angular.IScope & {
    initialized: boolean;
    tabs?: {
        available: IGridActionsModel[];
        selected: IGridActionsModel;
        toggleOffAllEditModes: (exclude?: unknown) => void;
    };
    isPanelOpen?: boolean;
    actionsModel: null | IGridActionsModel;
    actionsPanelModel?: ActionsPanelModel;
    sidebarModel?: null | SidebarModel;
    toggleSidebar?: (event: Event) => void;
    experiments?: Record<string, undefined | boolean>;
    openPanel: () => void;
    isSidebarDisabled?: boolean;
    export: () => void;
    pageCount?: undefined | null | string | number;
    disableExportButton?: boolean;
    exportButtonText?: null | string;
    forceMetricsInSidebar?: boolean;
    pebblesGroupByModel?: unknown;
}>}
###
($q, $timeout, ItemGridQuery, OutsideElementClick) ->
    restrict: 'E'
    scope:
        tabs: '=model'
        experiments: '='
    replace: true
    template: \
    """
    <article class="view-container view-items-container notransition"
        ng-class="{
            'hide-panel': !isPanelOpen,
            'experiments-sidebar': !isSidebarDisabled,
        }">
        <header class="action-header">
            <div class="action-header-actions-panel-options">
                <div class="left-side" ng-if="pebblesGroupByModel">
                    <properties-items model="pebblesGroupByModel"></properties-items>
                </div>
            </div>
        </header>
        <main>

            <article class="grid-bar" ng-if="isSidebarDisabled">
                <item-natural-language-query model="actionsModel"></item-natural-language-query>
                <button-export
                    on-click="export()"
                    text="exportButtonText"
                    disable="disableExportButton"
                    ng-if="pageCount && pageCount > 0">
                </button-export>
                <button
                    class="button-toggle-actions-panel button-toggle-actions-panel-show"
                    ng-click="openPanel()">
                    <span>Show Panel</span>
                    <i class="icon-down-open"></i>
                </button>
            </article>

            <div class="metrics-funnel-breadcrumb-actions-panel">
                <div class="left-panel" ng-if="!isSidebarDisabled">
                    <div class="sidebar-header-close"
                        ng-class="{'closed': !sidebarModel.toggle.isActive}"
                        ng-click="toggleSidebar($event)">
                        <div class="sidebar-toggle-icon">
                            <i class="icon-left-open"></i>
                        </div>
                    </div>
                </div>
                <actions-panel ng-if="actionsPanelModel.items.length > 0" model="actionsPanelModel"></actions-panel>
            </div>

            <div class="main-part">
                <sidebar ng-if="!isSidebarDisabled" model="sidebarModel"></sidebar>
                <div class="main-body-part">
                    <article class="grid-bar" ng-if="!isSidebarDisabled">
                        <item-natural-language-query model="actionsModel"></item-natural-language-query>
                        <button-export
                            on-click="export()"
                            text="exportButtonText"
                            disable="disableExportButton"
                            ng-if="pageCount && pageCount > 0">
                        </button-export>
                    </article>
                    <item-grid
                        model="actionsModel"
                        page-count="pageCount"
                    ></item-grid>
                </div>
            </div>
        </main>
    </article>
    """
    link: (scope, element) ->
        $element = $(element)
        $header = $element.find('> header')
        scope.actionsModel = null
        scope.initialized = false
        scope.isPanelOpen = true

        OutsideElementClick scope, $element.find('.ui-tabs, .ui-tab'), ->
            scope.tabs?.toggleOffAllEditModes()
            return

        ###* @param {undefined | boolean} [active] ###
        updatePanel = (active = true) ->
            scope.isPanelOpen = active
            $element.css({ top: '0px' }) if active
            $element.css({ top: -($header.height() ? 0) }) if not active
            return

        ###* @type {null | angular.IPromise<void>} ###
        transitionEnableTimeout = null
        transitionEnableTimeoutCB = () ->
            $timeout.cancel(transitionEnableTimeout) if transitionEnableTimeout
            $element[0]?.classList.remove('notransition')
            transitionEnableTimeout = $timeout((-> $element[0]?.classList.add('notransition')), 3000)

        scope.$watch 'actionsModel.views.panel.state.isOpen', (isOpen) ->
            updatePanel(isOpen)
            return

        scope.openPanel = ->
            transitionEnableTimeoutCB()
            scope.actionsModel?.views.panel.open()
            scope.actionsModel?.save()
            return

        scope.toggleSidebar = (event) ->
            return if not scope.sidebarModel
            isActive = scope.sidebarModel.toggle.isActive
            return scope.sidebarModel.toggle.open(event, 'properties') if not isActive
            return scope.sidebarModel.toggle.close(event)

        scope.export = ->
            return if not scope.actionsModel
            Analytics.track(Analytics.EVENTS.USER_EXPORTED_PAGE_GRID)
            if typeof scope.pageCount is 'number' and scope.pageCount >= ITEM_EXPORT_OPTIONS.maxPages
                alert \
                """
                Sorry, but you're trying to export too much data...
                This would generate a #{scope.pageCount} page PDF!

                Use the filters to reduce the data size, and try the export again.
                """
                return $q.reject()
            query = scope.actionsModel.toExportQuery()
            return ItemGridQuery.fetch(query)
                .then(QueryServiceExport.downloadAs('items-export.pdf'))

        scope.$watch 'tabs', (tabs) ->
            scope.initialized = Boolean(tabs)
            return

        scope.$watch 'tabs.selected', (model) ->
            scope.actionsModel = model
            updateFromActionModel(model)
            scope.tabs?.toggleOffAllEditModes(model) if model
            return

        scope.$watch 'pageCount', (pageCount) ->
            scope.disableExportButton = pageCount >= ITEM_EXPORT_OPTIONS.maxPages
            scope.exportButtonText = do ->
                return null if typeof pageCount isnt 'number' or pageCount <= 0
                return "export (#{pageCount} page)" if pageCount is 1
                return "export (#{pageCount} pages)"

        scope.$watch 'actionsModel.selected.itemsGroupBy.id', ->
            scope.actionsModel?.updateExtraItemInfo()
            return

        ###* @type {(() => void)[]} ###
        unWatchers = []

        # FIXME: embed experiments in the model or something...
        ###* @param {undefined | IGridActionsModel} model ###
        updateFromActionModel = (model) ->
            console.log("[grid] updateFromActionModel:", model)
            unWatchers.forEach((unWatch) -> unWatch?())
            unWatchers = []
            return if not model

            experiments = (scope.experiments ? {})
            {sidebar, metricsCategorization} = experiments
            sidebar ?= false

            scope.isSidebarDisabled = not sidebar
            # In the Grid Page it's not possible to have groupByPropertiesInSidebar and NOT metricsInSidebar at the same time
            scope.forceMetricsInSidebar = sidebar

            if scope.isSidebarDisabled
                scope.pebblesGroupByModel =
                    label: 'Rows By',
                    available: model.values.groupBy
                    selected: model.selected.groupBy
                    onClick: (property) ->
                        model.selected.groupBy = property[0]
                        return

            sidebarModel = do ->
                return null if scope.isSidebarDisabled
                return new SidebarModel({
                    options: hideTabs: true
                    properties:
                        selected: do ->
                            return model.selected.groupBy if Array.isArray(model.selected.groupBy)
                            return [model.selected.groupBy]
                        available: model.values.groupBy
                        pinnedProperties: ['stores.company']
                        selectProperty: (properties) ->
                            return if not properties[0]
                            model.selected.groupBy = properties[0]
                            return
                    displayBy:
                        available: model.values.itemsGroupBy
                        selected: do ->
                            return model.selected.itemsGroupBy if Array.isArray(model.selected.itemsGroupBy)
                            return [model.selected.itemsGroupBy]
                        selectProperty: (item) ->
                            model.selected.itemsGroupBy = item[0]
                            return
                    metrics:
                        selected: model.selected.metrics
                        available: model.values.metrics
                        options: { addAllMetrics: false, hideCategories: not metricsCategorization }
                        selectMetrics: (metrics) ->
                            model.setSelectedMetrics(metrics)
                            # FIXME: same array object used for both models...
                            sidebarModel.metrics.selected = model.selected.metrics if sidebarModel and sidebarModel.metrics
                            return
                    toggle: scope.sidebarModel?.toggle
                })
            scope.sidebarModel = sidebarModel

            scope.actionsPanelModel = do ->
                ###* @type {ActionsPanelModel['items']} ###
                actionsPanelModel = []

                if sidebarModel
                    unWatchers.push do (sidebarModel) ->
                        groupByActionItemModel = new ActionsPanelButtonItem({
                            label: 'Rows By',
                            selected: model.selected.groupBy.label
                            cssClass: 'sidebar-toggle'
                            icon:
                                type: 'icon-down-open'
                            isActive: ->
                                sidebarModel.toggle.isActive and sidebarModel.toggle.tab is 'properties'
                            onClick: ($event) ->
                                $event.preventDefault()
                                $event.stopImmediatePropagation()
                                sidebarModel.toggle.toggle($event, 'properties')
                                return
                        })
                        actionsPanelModel.push(groupByActionItemModel)
                        return scope.$watch 'actionsModel.selected.groupBy', (groupBy) ->
                            groupByActionItemModel.selected = groupBy.label
                            return

                    unWatchers.push do (sidebarModel) ->
                        displayByActionItemModel = new ActionsPanelButtonItem({
                            label: 'Columns By',
                            selected: model.selected.itemsGroupBy.label
                            cssClass: 'sidebar-toggle'
                            icon:
                                type: 'icon-down-open'
                            isActive: -> sidebarModel.toggle.isActive and sidebarModel.toggle.tab is 'displayBy'
                            onClick: ($event) ->
                                $event.preventDefault()
                                $event.stopImmediatePropagation()
                                sidebarModel.toggle.toggle($event, 'displayBy')
                                return
                        })
                        actionsPanelModel.push(displayByActionItemModel)
                        return scope.$watch 'actionsModel.selected.itemsGroupBy', (itemsGroupBy) ->
                            displayByActionItemModel.selected = itemsGroupBy.label
                            return

                if scope.isSidebarDisabled
                    actionsPanelModel.push(new ActionsPanelSelectItem({
                        label: 'Columns By',
                        available: model.values.itemsGroupBy
                        selected: model.selected.itemsGroupBy
                        icon:
                            type: 'icon-down-open'
                        onClick: (item) ->
                            model.selected.itemsGroupBy = item
                            return
                    }))

                actionsPanelModel.push new ActionsPanelSelectItem do (model) ->
                    label: 'Sort By',
                    available: model.values.itemsSortBy
                    selected: model.selected.itemsSortBy
                    icon:
                        type: 'icon-down-open'
                    onClick: (item) ->
                        model.selected.itemsSortBy = item
                        return

                actionsPanelModel.push new ActionsPanelSelectItem do (model) ->
                    label: 'Sort Order',
                    available: model.values.itemsSortOrder
                    selected: model.values.itemsSortOrder.find((x) -> x.id is model.selected.itemsSortOrder)
                    icon:
                        type: 'icon-down-open'
                    onClick: (item) ->
                        model.selected.itemsSortOrder = item.id
                        return

                actionsPanelModel.push new ActionsPanelSelectItem do (model) ->
                    itemsLimitBy = model.values.itemsLimitBy.map((limit) -> { label: limit })
                    label: 'Limit',
                    available: itemsLimitBy
                    selected: itemsLimitBy.find((limit) -> limit.label is model.selected.itemsLimitBy)
                    icon:
                        type: 'icon-down-open'
                    onClick: (item) ->
                        model.selected.itemsLimitBy = item.label
                        return

                if not scope.isSidebarDisabled
                    actionsPanelModel.push new ActionsPanelSimpleItem do (sidebarModel) ->
                        label: 'Edit Metrics',
                        icon:
                            type: 'icon-flow-cascade'
                        cssClass: 'sidebar-toggle'
                        isActive: ->
                            return false if not sidebarModel
                            return sidebarModel.toggle.isActive and sidebarModel.toggle.tab is 'metrics'
                        onClick: ($event) ->
                            $event.preventDefault()
                            $event.stopImmediatePropagation()
                            sidebarModel?.toggle.toggle($event, 'metrics')
                            return
                else
                    metricsActionItemModel = new ActionsCustomMetricTreeModel do (model) ->
                        selected: model.selected.metrics
                        available: model.values.metrics
                        options: { addAllMetrics: false, hideCategories: true }
                        onChange: (metrics) ->
                            # FIXME: monkey patching the model...?
                            model.setSelectedMetrics(metrics)
                            metricsActionItemModel.selected = model.selected.metrics
                            return
                    actionsPanelModel.push(metricsActionItemModel)

                unWatchers.push do ->
                    ###* @param {undefined | {isOpen:boolean}} state ###
                    getLabel = (state) ->
                        return 'Hide Images' if (state?.isOpen ? true)
                        return 'Show Images'
                    imagesToggleItemModel = new ActionsPanelSimpleItem do (model) ->
                        secondary: true,
                        label: getLabel(model.views.images.state)
                        cssClass: 'reverse'
                        icon:
                            type: 'icon-picture'
                        isActive: ->
                            return model.views.images.state.isOpen ? true
                        onClick: ($event) ->
                            $event.preventDefault()
                            $event.stopImmediatePropagation()
                            model.views.images.toggle()
                            return
                    actionsPanelModel.push(imagesToggleItemModel)
                    return scope.$watch 'actionsModel.views.images.state', (state) ->
                        imagesToggleItemModel.label = getLabel(state)
                        return

                if scope.isSidebarDisabled
                    unWatchers.push do ->
                        hidePanelItemModel = new ActionsPanelSimpleItem({
                            secondary: true,
                            label: 'Hide Panel'
                            cssClass: 'button-toggle-actions-panel'
                            icon:
                                type: 'icon-up-open'
                            onClick: ($event) ->
                                $event?.preventDefault()
                                $event?.stopImmediatePropagation()
                                transitionEnableTimeoutCB()
                                model.views.panel.close()
                                model.save()
                                return
                        })
                        actionsPanelModel.push(hidePanelItemModel)
                        return scope.$watch 'actionsModel.views.panel.state', (state) ->
                            hidePanelItemModel.cssClass = do ->
                                return 'hide' if state?.isOpen is false
                                return ''
                            return

                return new ActionsPanelModel({items: actionsPanelModel})

        resizeObserver = new ResizeObserver (-> updatePanel(scope.actionsModel?.views?.panel.isActive ? false))
        resizeObserver.observe($element[0])
        scope.$on('$destroy', -> resizeObserver.disconnect())

        return
]


###*
@typedef {InstanceType<typeof ItemGridDataViewModel>} IItemGridDataViewModel
@typedef {import('angular').IPromise<{ items: Record<string, unknown>[] }[]>} IPromiseItemGridQueryFetch>
@typedef {import('./items-controller.d.ts').IItemGridQuery } IItemGridQuery
###

module.directive 'itemGrid', ['$timeout','$rootScope','ItemGridQuery','ItemGridModel',
###*
@param {angular.ITimeoutService} $timeout
@param {DashboardRootScope} $rootScope
@param {IItemGridQuery} ItemGridQuery
@param {_ItemGridModel} ItemGridModel
@returns {angular.IDirective<angular.IScope & {
    model?: IGridActionsModel;
    view: { grid: null | IItemGridModel; resultSet: null | IItemGridDataViewModel };
    pageCount: undefined | null | string | number;
}>}
###
($timeout, $rootScope, ItemGridQuery, ItemGridModel, METRIC_RESTRICTIONS) ->
    restrict: 'E'
    scope:
        model:     '='
        pageCount: '='
    replace: true
    template: \
    """
    <article class="item-grid">
        <div class="grid-container card" ng-class="{loading: (!view.resultSet || view.resultSet.loading)}" ng-if="view.grid && model">
            <div ag-grid="view.grid.options" class="ag-42 grid grid-new ag-theme-alpine" ng-class="{'grid-no-filter':!view.grid.options.enableFilter}"></div>
        </div>
    </article>
    """
    link: (scope, $element) ->
        scope.pageCount = undefined

        scope.view = {
            grid      : null,
            resultSet : null
        }

        ###*
        @param {IGridActionsModel} model
        @param {unknown[]} data
        @returns {number}
        ###
        getPageCountEstimate = (model, data) ->
            return -1 if not model.selected
            return  0 if data.length is 0
            pageCount = getNumberOfPagesEstimate({
                data,
                metrics: Utils.Object.pickValues(model.values.metricsByField, model.selected.metrics)
                itemsExtraInfo: model.selected.itemsExtraInfo,
                itemsGroupBy: model.selected.itemsGroupBy,
                columnDefs: scope.view.grid?.columnDefs ? [],
            })
            hasFilters = do ->
                queryFilters = $rootScope.query?.filters ? {}
                return Object.keys(queryFilters).filter((x) -> x isnt 'transactions').length > 0
            return pageCount + 1 if hasFilters
            return pageCount


        ###* @param {undefined | null | {items: Record<string, unknown>[]}[]} data ###
        updatePageCount = (data) ->
            model = scope.model ? null
            if not model
                scope.pageCount = undefined
                return
            # Do this in the next tick, because it's possibly an expensive operation
            $timeout((-> scope.pageCount = do ->
                return if not model
                return if not data
                return (try getPageCountEstimate(model, data)) or 'unknown'
            ), 0)
            return

        ###* @param {IGridActionsModel} model ###
        doGridDataRefresh = (model) ->
            scope.view.grid?.updateColumnDefs(model)
            scope.view.resultSet = new ItemGridDataViewModel(model, ItemGridQuery)
            return

        ###* @param {IGridActionsModel} model ###
        doGridRendererRefresh = (model) ->
            scope.view.grid?.updateCellRenderers(model)
            return

        scope.$watch 'view.resultSet.data', do ->
            ###* @param {undefined | null | {items: Record<string, unknown>[]}[]} data ###
            return (data) ->
                scope.view.grid?.setError(true) if data and scope.view.resultSet?.error
                updatePageCount(data)
                scope.view.grid?.updateData(data ? null)
                return

        ###*
        @param {undefined | IGridActionsModel['selected']} curr
        @param {undefined | IGridActionsModel['selected']} prev
        ###
        updateModel = (curr, prev) ->
            return if not curr or not scope.model
            scope.view.grid ?= ItemGridModel(scope.model)

            needsDataRefresh =
                model          : not prev
                groupBy        : not prev or Utils.Object.hash(curr.groupBy?.id)      isnt Utils.Object.hash(prev.groupBy?.id)
                itemsGroupBy   : not prev or Utils.Object.hash(curr.itemsGroupBy?.id) isnt Utils.Object.hash(prev.itemsGroupBy?.id)
                itemsLimitBy   : not prev or Utils.Object.hash(curr.itemsLimitBy)     isnt Utils.Object.hash(prev.itemsLimitBy)
                itemsSortBy    : not prev or Utils.Object.hash(curr.itemsSortBy?.id)  isnt Utils.Object.hash(prev.itemsSortBy?.id)
                itemsSortOrder : not prev or Utils.Object.hash(curr.itemsSortOrder)   isnt Utils.Object.hash(prev.itemsSortOrder)
                # itemsExtraInfo : not prev or (curr.itemsExtraInfo isnt prev.itemsExtraInfo)

            needsRendererRefresh = curr and (not prev or do ->
                curr.metrics        isnt prev.metrics or
                curr.itemsThemeLarge isnt prev.itemsThemeLarge
            )

            console.log('[grid] model.selected changed:', { needsDataRefresh, needsRendererRefresh })
            doGridDataRefresh(scope.model) if Object.values(needsDataRefresh).some((x) -> x)
            doGridRendererRefresh(scope.model) if needsRendererRefresh
            scope.model?.save()
            return

        scope.$watch('model', ((curr, prev) ->
            return if not curr
            updateModel(curr?.selected, prev?.selected)
            return
        ), true)

        scope.$watch 'model.name', _.debounce((->
            scope.model?.save()
            scope.$apply()
            return
        ), 600)

        scope.$watch 'model.views.images.state', (state) ->
            return if state is undefined
            scope.view.grid?.toggleImages(state?.isOpen ? true)
            scope.model?.save()
            return
]

module.constant 'ITEM_INDEPENDENT_PROPERTIES', [
    'items.variant_option_size'
    'items.size'
    'items.variant_option_color'
    'items.color_no'
    'items.color_code'
    'items.color'
    'items.season'
    'items.season_code'
    'items.season_name'
    'items.vendor'
    'items.gender'

    'items.live_on_site'
    'items.published_at_aging_bucket'

    # marolina
    'items.current_season'
    'items.original_season'
    'items.season_status'
]


module.directive 'itemNaturalLanguageQuery', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="item-natural-language-query" ng-show="model">
        <span>Showing the</span>
        <span class="selected" ng-if="model.selected.itemsLimitBy > 1">top {{ model.selected.itemsLimitBy }}</span>
        <span>best</span>
        <span class="selected" ng-if="model.selected.itemsLimitBy > 1">{{ model.selected.itemsGroupBy.plural || model.selected.itemsGroupBy.label }}</span>
        <span class="selected" ng-if="model.selected.itemsLimitBy <= 1">{{ model.selected.itemsGroupBy.label }}</span>
        <span>by</span>
        <span class="selected">{{ model.selected.itemsSortBy.label }}</span>
        <span ng-if="model.selected.groupBy.id != 'stores.company'">for each</span>
        <span ng-if="model.selected.groupBy.id != 'stores.company'" class="selected">{{ model.selected.groupBy.label }}</span>
    </article>
    """


ItemGridModel = ['$filter',
###* @param {angular.IFilterService} $filter ###
($filter) ->

    ###* @typedef {{headerName: string, cellRenderer?: {new(): IGridPageCellRenderer}, pinned?: string}} ColumnDef ###

    ###* @param {IGridActionsModel} actionsModel ###
    generateColumnDefs = (actionsModel) ->
        GridItemCellRenderer = GridItemCellRendererFactory($filter, actionsModel)
        GridInfoCellRenderer = GridInfoCellRendererFactory($filter, actionsModel)
        ###* @type {ColumnDef[]} result ###
        result = [
            {
                headerName: actionsModel.selected?.groupBy.label ? ''
                cellRenderer: GridInfoCellRenderer
                pinned: 'left'
            },
            ...(_.range(actionsModel.selected?.itemsLimitBy ? 0).map((x, index) -> ({
                headerName: String(index+1),
                cellRenderer: GridItemCellRenderer
            })))
        ]
        return result

    overlay = GridOverlayMessageRendererFactory()

    ###* @param {IGridActionsModel} actionsModel ###
    return (actionsModel) ->
        ###* @type {ColumnDef[]} columnDefs  ###
        columnDefs = generateColumnDefs(actionsModel)
        columnDefs: columnDefs
        overlay: overlay
        ###* @type {import('@ag-grid-community/core').GridOptions} ###
        options:
            api: null
            columnDefs: [...columnDefs]
            defaultColDef:
                autoHeight: true
                resizable: false
                suppressMovable: true
                suppressMenu: true
                sortable: false
                filter: false
            rowBuffer: 10
            headerHeight: 35
            colWidth: 200
            sortingOrder: ['desc','asc',null]
            localeText:
                loadingOoo: ' '
            noRowsOverlayComponent: overlay.component

        ###*
        @param {IGridActionsModel} actionsModel
        ###
        updateColumnDefs: (actionsModel) ->
            console.log('[grid][ItemGridModel] updateColumnDefs:', actionsModel)
            @columnDefs = generateColumnDefs(actionsModel)
            setTimeout((() =>
                @options.api?.setColumnDefs(@columnDefs.map((x) -> {...x, filter:false}))
                @updateRowHeights()
            ), 0)
            return

        ###*
        @param {null | {items: Record<string, unknown>[]}[]} data
        ###
        updateData: (data) ->
            return if not Array.isArray(data)
            console.log('[grid] gridDataModel.data changed:', data)
            @options.api?.setRowData(data ? [])
            @updateRowHeights()
            return

        updateRowHeights: ->
            @options.api?.resetRowHeights()
            return

        ###*
        @param {IGridActionsModel} actionsModel
        ###
        updateCellRenderers: (actionsModel) ->
            ###* @type {(InstanceType<ReturnType<GridItemCellRendererFactory> | ReturnType<GridInfoCellRendererFactory>>)[]} ###
            # @ts-expect-error don't know how to cast this...
            renderers = @options.api?.getCellRendererInstances() ? []
            for cellRenderer from renderers
                cellRenderer.setMetrics(actionsModel.selected?.metrics)
            @updateRowHeights()
            return

        ###*
        @param {boolean} imagesEnabled
        ###
        toggleImages: (imagesEnabled) ->
            ###* @type {(InstanceType<ReturnType<GridItemCellRendererFactory> | ReturnType<GridInfoCellRendererFactory>>)[]} ###
            # @ts-expect-error don't know how to cast this...
            renderers = @options.api?.getCellRendererInstances() ? []
            for cellRenderer from renderers
                continue if not ('setImagesEnabled' of cellRenderer)
                cellRenderer.setImagesEnabled(imagesEnabled)
            @updateRowHeights()
            return

        ###* @param {boolean} error ###
        setError: (error) ->
            @options.api?.hideOverlay()
            @overlay.setError(error)
            @options.api?.showNoRowsOverlay() if error
            return
]

module.factory('ItemGridModel', ItemGridModel)

_ItemActionModelList = ['$q', 'ItemActionsModelState', 'ItemActionModel',
###*
@param {angular.IQService} $q
@param {ItemActionsModelStateService} ItemActionsModelState
@param {GridActionsModel} ItemActionModel
###
($q, ItemActionsModelState, ItemActionModel) -> return class ItemActionModelList

    ###* @param {unknown} state ###
    constructor: (state) ->
        {selected, available} = state
        @available = available.map (x) => new ItemActionModel(@save, x)
        @selected = _.find @available, (x) -> x.id is (selected.id or selected)
        @selected ?= @available[0]
        @id = Utils.uuid()

    add: =>
        ItemActionsModelState.createState().then (state) =>
            model = new ItemActionModel(@save, state)
            @available.push(model)
            @selected = model
        @save()

    duplicate: (id) =>
        elementToDuplicate = @available.find (x) -> x.id is id
        ItemActionsModelState.duplicate(elementToDuplicate).then (newItem) =>
            model = new ItemActionModel(@save, newItem)
            @available.push(model)
            @selected = model
        @save()

    remove: (id) =>
        @available = @available.filter (x) -> x.id isnt id
        @selected = @available[0] or @selected
        @save()

    createTabFromJSON: (viewConfig) =>
        delete viewConfig.id
        viewConfig.name = "#{viewConfig.name} (shared)"
        ItemActionsModelState.createState(viewConfig).then (state) =>
            model = new ItemActionModel(@save, state)
            @available.push(model)
            @selected = model

    @ValidateViewConfigFile = (payload, orgId) ->
        try
            payload = JSON.parse(payload) if typeof payload is 'string'
        catch error
            console.error(error)
            throw new Error("View config import error: bad json.", {cause:error})
        if not isObject(payload)
            throw new Error("View config import error: must be a string or object")

        meta = GridPageViewMetaSchema.safeParse(payload.meta)
        if not meta.success
            console.error(meta.error)
            throw new Error('View config import error: meta field missing or invalid.')

        if meta.data.organizationId isnt orgId
            throw new Error('View config import error: incorrect organization.')

        parsedData = GridPageViewDataSchema.safeParse(payload.data)
        if not parsedData.success
            console.error(parsedData.error)
            throw new Error('Grid Page View config import error: data field missing or invalid.')

        return parsedData.data


    exportTab: () => $q.when do =>
        { available, selected } = ItemActionsModelState.serializeStates(@)
        data = _.find available, (x) -> x.id is selected
        downloadFile({
            data
            name: data.name
            type: 'tab-grid'
            namespace: 'grid'
            analyticsEvent: Analytics.EVENTS.USER_EXPORT_GRID_TAB
        })

    save: =>
        return ItemActionsModelState.save(@)

    reorder: (oldIndex, newIndex) =>
        @available = Utils.Array.move(@available, oldIndex, newIndex)
        @selected = @available[newIndex]
        @save()

    toggleOffAllEditModes: (exclude) =>
        toToggleOff = if exclude then @available.filter((x) -> exclude isnt x) else @available
        toToggleOff.forEach (other) ->
            other.fillNameIfNeeded()
            other.editMode = false
            other.dropdown = false
]
module.factory('ItemActionModelList', _ItemActionModelList)


module.factory 'ItemActionModel', ['$rootScope','QueryMetrics','ITEM_INDEPENDENT_PROPERTIES',
###*
@param {DashboardRootScope} $rootScope
@param {IQueryMetrics} QueryMetrics
@param {string[]} ITEM_INDEPENDENT_PROPERTIES
###
($rootScope, QueryMetrics, ITEM_INDEPENDENT_PROPERTIES) -> return class ItemActionModel

    ###*
    @param {(...args: any) => any} parentSave
    @param {{
        id: string;
        name: string;
        selected: IGridActionsModel['selected'];
        values: IGridActionsModel['values'];
        views: {
            panel?: boolean | null;
            images?: boolean | null;
        };
    }} state
    ###
    constructor: (@parentSave, state) ->
        throw new Error("Missing required `values` property.") if not state?.values
        {@id, @name, values, views} = state
        @fillNameIfNeeded()
        @editMode = false
        @dropdown = false

        ###* @type {IGridActionsModel['values']} values ###
        @values = values

        ###* @type {IGridActionsModel['selected']} selected ###
        @selected = {
            ...(state.selected ? {}),
            groupBy        : state.selected?.groupBy        ? values.groupBy[0],
            itemsGroupBy   : state.selected?.itemsGroupBy   ? values.itemsGroupBy[0],
            itemsSortBy    : state.selected?.itemsSortBy    ? values.itemsSortBy[0],
            itemsLimitBy   : state.selected?.itemsLimitBy   ? 15,
            itemsSortOrder : state.selected?.itemsSortOrder ? -1,
            itemsThemeLarge : state.selected?.itemsThemeLarge ? false,
            itemsExtraInfo : []
        }

        ###* @type {IGridActionsModel['views']} views ###
        @views =
            metrics : new ToggleModel(false)
            panel   : ToggleModel.Deserialize(views?.panel, true)
            images  : ToggleModel.Deserialize(views?.images, true)

        @updateExtraItemInfo()
        return


    fillNameIfNeeded: ->
        @name = if @name.length is 0 then "New View" else @name
        return

    ###* @returns {boolean} ###
    toggleTabNameEditor: ->
        @fillNameIfNeeded()
        @editMode = not @editMode

    ###* @returns {boolean} ###
    toggleDropdown: ->
        @fillNameIfNeeded()
        @dropdown = not @dropdown

    ###* @returns {IPropertyDefinition[]} ###
    updateExtraItemInfo: ->
        @selected.itemsExtraInfo = @_getExtraItemInfo()
        return @selected.itemsExtraInfo

    ###* @param {(IMetricDefinition | string)[]} metrics ###
    setSelectedMetrics: (metrics) ->
        fields = metrics.map((x) -> if typeof x is 'string' then x else x.field)
        fields = Utils.Object.pickValues(@values.metricsByField, fields).map((x) -> x.field)
        @selected.metrics = fields
        return

    ###* @returns {IPropertyDefinition[]} ###
    _getExtraItemInfo: ->
        selectedGroupBy = @selected.itemsGroupBy
        availableGroupBy = @values?.itemsGroupBy
        return [] if not selectedGroupBy
        return [] if not availableGroupBy
        return [] if selectedGroupBy.table isnt 'items'
        return [] if ITEM_INDEPENDENT_PROPERTIES.includes(selectedGroupBy.id)

        # This function defines which extra properties should be listed with the item.
        ###* @param {IPropertyDefinition} property ###
        extraPropertyShouldBeShown = (property) ->
            isValidField = ['items.name', 'items.product_name', 'items.item_description'].includes(property.id)
            isNotSelected = property.id isnt selectedGroupBy?.id
            return isValidField and isNotSelected

        extraItemProperties = Utils.copy(availableGroupBy ? []).filter(extraPropertyShouldBeShown)
        selectionPosition = availableGroupBy.findIndex((x) -> selectedGroupBy?.id is x.id)
        ###* @type {IPropertyDefinition[]} result ###
        result = []
        return extraItemProperties.reduce(((result, property) ->
            propertyPosition = availableGroupBy?.findIndex((x) -> property.id is x.id) ? -1
            return result if selectionPosition < propertyPosition or propertyPosition is -1
            return [...result, property]
        ), result)

    ###* @returns {Promise<void>} ###
    save: =>
        return @parentSave()

    ###* @param {DashboardRootScope['query']} [baseQuery] ###
    toQuery: (baseQuery) ->
        query = Utils.copy(baseQuery ? $rootScope.query ? {})
        delete query.sort
        query.options =
            metrics        : @values.metrics.map((x) -> x.field)
            groupBy        : @selected.groupBy.id
            itemsGroupBy   : @selected.itemsGroupBy.id
            itemsSortBy    : @selected.itemsSortBy.id
            itemsLimitBy   : @selected.itemsLimitBy
            itemsSortOrder : @selected.itemsSortOrder ? -1
        return query

    ###* @param {DashboardRootScope['query']} [baseQuery] ###
    toExportQuery: (baseQuery) ->
        query = @toQuery(baseQuery)
        query.type = "pdf"
        query.export = Utils.copy do =>
            values: @values
            selected: @selected
            options: ITEM_EXPORT_OPTIONS
        return query
]
