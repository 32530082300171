import _ from 'lodash';
import { ISelectorTreeNode } from '../../components/metrics-selector-tree/metrics-selector-tree.builder';
import { IncludesFilter } from '../../filters/includes.filter';
import { IMetricDefinition } from '../types';

export const METRICS_HEADER_NAMES_BLACK_LIST = [
    'TY',
    '% Change to LY',
    'Change to LY',
    'LY',
    'TY % TTL',
    'LY % TTL',
    'TY % Parent TTL',
    'LY % Parent TTL',
];

export function onSearchMetricsTree(value: string, node: ISelectorTreeNode) {
    // INFO: not allowing to search for leaf nodes
    // if (metricSelect?.isLeaf(node) && node.parent !== '#' && node.parents.length > 2)
    //     return false;

    const metricName = (() => {
        const name: unknown = _.get(node, 'data.metric.headerName');
        if (typeof name === 'string') return name;
        return '';
    })();

    const hasHeaderGroupMatched = IncludesFilter.parse(node.text, value, false).length > 0;
    const hasHeaderNameMatched =
        metricName === ''
            ? true
            : IncludesFilter.parse(metricName, value, false, METRICS_HEADER_NAMES_BLACK_LIST).length > 0;

    return hasHeaderGroupMatched && hasHeaderNameMatched;
}

export function onSearchMetrics(metric: IMetricDefinition, value: string) {
    const { headerGroup, headerName, category } = metric;

    const hasMatchingHeaderGroup =
        IncludesFilter.parse(headerGroup, value, false, METRICS_HEADER_NAMES_BLACK_LIST).length > 0;
    const hasMatchingHeaderName =
        IncludesFilter.parse(headerName, value, false, METRICS_HEADER_NAMES_BLACK_LIST).length > 0;
    const hasMatchingCategory =
        IncludesFilter.parse(category, value, false, METRICS_HEADER_NAMES_BLACK_LIST).length > 0;

    return !hasMatchingCategory && !hasMatchingHeaderGroup && !hasMatchingHeaderName;
}
