import _ from 'lodash'
import moment from 'moment'
import Utils from '../lib/utils'
import { CustomError } from '../lib/utils'
import { isRequestAbortedError } from '../lib/api'
import * as Auth from '../lib/auth'
import * as ConfigFlags from '../lib/config-flags'
import * as ConfigExperimentsAPI from '../lib/config-experiments'
import * as QueryBuilder from '../lib/query/query-builder'
import { ConfigAPI } from '../lib/config-api'
import { QueryMetrics as QueryMetricsAPI } from '../lib/config-metrics'
import { fetchHourProperty, fetchCalendarProperties } from '../lib/config-hierarchy'
import { Browser, LoadingStatus, DatabaseStatusMonitor } from '../lib/services'
import { DatabaseDescriptorsService } from '../lib/config-database-descriptors'
import DashboardModelsModule from './main/dashboard-models'
import DashboardNavbarModule from './main/main-navbar'
import DashboardStatusBarModule from './main/status-message-bar-module.coffee'
import { StorageDatepickerCalendarId } from '../modules/datepicker/datepicker-storage-selected-calendar'

module = angular.module '42.controllers.main', [
    '42.modules',
    DashboardModelsModule.name,
    DashboardNavbarModule.name,
    DashboardStatusBarModule.name
]

class DataLoadingInProgressError extends CustomError
    constructor: ->
        super('Data loading is in progress')


class UserSuspendedError extends CustomError
    constructor: ->
        super('User is suspended')


class DashboardSuspendedError extends CustomError
    constructor: (msg) -> super(msg ? 'Dashboard is suspended [0]')
class DashboardSuspendedPaymentError extends DashboardSuspendedError
    constructor: -> super('Dashboard is suspended [P00]')
class DashboardSuspendedFrasersGroupInternalError extends DashboardSuspendedError
    constructor: -> super('Dashboard is suspended [FG0]')
class DashboardSuspendedFrasersGroupExternalError extends DashboardSuspendedError
    constructor: -> super('Dashboard is suspended [FG5]')


class DashboardInitError extends CustomError
    constructor: ->
        super('Dashboard could not be initialized')


SIMULATE_USER_SUSPENDED = false and Browser.isLocalhost()
SIMULATE_DASH_SUSPENDED = false and Browser.isLocalhost()
SIMULATE_DASH_NOT_READY = false and Browser.isLocalhost()


module.controller 'MainController',
($rootScope, $scope, $q, $timeout, $window, CONFIG, DashboardFilterModel, DashboardCalendarModel, DashboardCurrencyModel, DashboardMaxTimestampModel, DashboardSmartGroupsModel, Hierarchy, DashboardQuery, DashboardSidebarToggleModel, DashboardDatabaseStatus, StatusMessageBarModel, AccessControl) ->
    LoadingStatus.loading("Initializing Dashboard")

    $scope.organization = CONFIG.organization

    $rootScope.DashboardQuery = DashboardQuery
    $rootScope.DashboardSidebarToggleModel = DashboardSidebarToggleModel
    $rootScope.Browser = Browser

    $scope.loading = false
    $rootScope.initialized = false

    $scope.DashboardDatabaseStatus = DashboardDatabaseStatus

    DatabaseStatusMonitor.addEventListener 'databaseStatusChanged', (event) ->
        ###* @ts-expect-error Don't know how to type this properly... ###
        status = event.detail.status
        DashboardDatabaseStatus.set(status)
        StatusMessageBarModel.set(status)
        $rootScope.$digest()

    return $q.all([
        Auth.getUser(),
        Auth.getOrganization()
    ])
    .catch (error) ->
        analytics.track("error/app/initialization-failed/session", {error}) if not isRequestAbortedError(error)
        LoadingStatus.error(LoadingStatus.Messages.InitializationError())
        return $q.reject(error)
    .then ([user, organization]) ->
        $rootScope.organizationId = organization
        return {user, organization}
    .then (context) ->
        isSuspended = (CONFIG.accessControl?.status is 'suspended') or SIMULATE_DASH_SUSPENDED
        # return $q.reject(new DashboardSuspendedFrasersGroupInternalError()) if context.organization.startsWith('sportsdirect')
        return context if not isSuspended
        return $q.reject(new DashboardSuspendedFrasersGroupInternalError()) if context.organization is 'sportsdirect'
        return $q.reject(new DashboardSuspendedFrasersGroupExternalError()) if context.organization.startsWith('sportsdirect_')
        return $q.reject(new DashboardSuspendedError())
    .then (context) ->
        promises = []

        promises.push $q.when(ConfigFlags.fetchPageFlags()).then (pageFlags) ->
            # try console.log("[PageFlags]:", JSON.stringify(pageFlags, null, 2))
            $scope.pages = pageFlags
            return

        promises.push $q.when(ConfigExperimentsAPI.fetch()).then (experiments) ->
            # try console.log("[ConfigExperimentsAPI]:", JSON.stringify(experiments, null, 2))
            $rootScope.Experiments = experiments
            return

        promises.push $q.when(ConfigFlags.fetch()).then (flags) ->
            # try console.log("[ConfigFlags]:", JSON.stringify(flags, null, 2))
            $rootScope.flags = flags ? {}
            return

        promises.push $q.when(AccessControl).then (accessControl) ->
            # try console.log("[AccessControl]:", JSON.stringify(accessControl, null, 2))
            $rootScope.accessControl = accessControl
            isSuspended = accessControl?.status is 'suspended'
            return $q.reject(new UserSuspendedError()) if isSuspended or SIMULATE_USER_SUSPENDED
            return

        return $q.all(promises).then(() -> context)

    .then(({user, organization}) ->
        return $q.when(DatabaseStatusMonitor.start())
        .then((status) -> {user, organization, status})
        .catch (error) ->
            if not isRequestAbortedError(error)
                analytics.track("error/app/initialization-failed/status", {error})
                LoadingStatus.error(LoadingStatus.Messages.InitializationError(organization))
            return $q.reject(error)
    )

    .then ({user, organization, status}) ->
        return $q.reject(new DataLoadingInProgressError()) if status.isLoading or SIMULATE_DASH_NOT_READY
        return {user, organization, status}

    .then ({organization, status}) ->
        DashboardMaxTimestampModel.updateDashboard(status.latestTransactionTimestamp)

        calendarModelPromise = do ->
            $q.when(DashboardCalendarModel.fetch()).then (model) ->
                update = ->
                    StorageDatepickerCalendarId.set(model.getState().calendarId)
                    DashboardCalendarModel.updateDashboard(model)
                $rootScope.calendarModel = model
                $rootScope.$watch('calendarModel.state.ref', update)
                update()
                return model

        currencyModelPromise = do ->
            $q.when(DashboardCurrencyModel.fetch()).then (model) ->
                update = ->
                    model.save()
                    DashboardCurrencyModel.updateDashboard(model)
                $rootScope.currencyModel = model
                $rootScope.$watch('currencyModel.selected.id', update)
                update()
                return model

        smartGroupsModelPromise = do ->
            $q.when(DashboardSmartGroupsModel.init()).then (SmartGroupsModel) ->
                $rootScope.SmartGroupsModel = SmartGroupsModel
                $rootScope.smartGroupsModel = new SmartGroupsModel()
                return SmartGroupsModel

        # this is to catch any errors with the hierarchy before we start the dashboard
        hierarchyPromise = do ->
            return Hierarchy.fetch()

        return $q.all([
            currencyModelPromise
            calendarModelPromise
            hierarchyPromise,
            smartGroupsModelPromise
        ]).catch (error) ->
            return $q.reject(error) if error instanceof UserSuspendedError
            analytics.track("error/app/initialization-failed", {error}) if not isRequestAbortedError(error)
            LoadingStatus.error(LoadingStatus.Messages.InitializationError(organization))
            return $q.reject(error)

    .then(([currencyModel, calendarModel]) ->

        LoadingStatus.loading("Applying finishing touches")

        updateQueryFromSmartGroup = (filters) ->
            return if not filters
            updatedFilters = do ->
                filters = _.cloneDeep(filters)
                Object.keys(filters).forEach (k) -> delete filters[k] if _.isEmpty(filters[k])
                return filters
            DashboardFilterModel.updateDashboard(calendarModel, updatedFilters)

        $rootScope.$watch 'smartGroupsModel.view.groups.view.selected.model.query', (query) ->
            updateQueryFromSmartGroup(query.filters)

        # FIXME: This probably doesn't work anymore...
        #
        # I think the intent behind this function is that, when we get a latest transaction timestamp
        # from the StatusMonitor, we only refresh the dashboard when the user is looking at the current day
        onLatestTransactionTimestampChanged = (latestTransactionTimestamp) ->
            return if not latestTransactionTimestamp
            return if not $rootScope.initialized
            timerange = try QueryBuilder.QueryTimestampSelection.get(DashboardQuery.get())
            return if not timerange
            start = moment.utc(timerange.$gte)
            end   = moment.utc(timerange.$lt)
            # The assumption here is that, if our timerange is small, then we care about "realtime" updates...
            dayDifference = end.add(1, 'day').diff(start, 'days')
            return if dayDifference >= 7
            # Unclear why there is a delay here?
            delay = Math.round(Math.random() * 1000 * 5)
            $timeout (->
                DashboardMaxTimestampModel.updateDashboard(latestTransactionTimestamp)
            ), delay

        $scope.$watch 'DashboardDatabaseStatus.state.status.latestTransactionTimestamp', onLatestTransactionTimestampChanged
        $scope.$watch 'DashboardDatabaseStatus.state.status.isLoading', (currIsLoading, prevIsLoading) ->
            return if _.isNil(currIsLoading)
            prevIsLoading ?= false
            loadingIsDone  = prevIsLoading is true  and currIsLoading is false
            loadingStarted = prevIsLoading is false and currIsLoading is true
            if loadingStarted
                $rootScope.initialized = false
                analytics.track("loading-start", {status: DashboardDatabaseStatus.get()})
                LoadingStatus.loading(LoadingStatus.Messages.LoadingDataFromAppStarted())
                return
            if loadingIsDone
                delay = Math.round(Math.random() * 1000 * 60)
                $timeout((() -> $window.location.reload()), delay)

        $rootScope.queryState =
            previous:         undefined
            changeCounter:    0
            broadcastCounter: 0

        broadcastQueryChange = (query) ->
            query ?= DashboardQuery.get()
            query = _.cloneDeep(query)
            $rootScope.queryState.broadcastCounter += 1
            $rootScope.$broadcast('query.refresh', {query})

        $rootScope.$watch 'DashboardQuery.state', ->
            prev = $rootScope.queryState.previous

            query = DashboardQuery.get()
            query = DashboardCalendarModel.updateQuery(calendarModel, query)
            query = DashboardCurrencyModel.updateQuery(currencyModel, query)
            return if Utils.Object.hash(query) is Utils.Object.hash(prev)

            console.group("Query changed")
            console.log "previous:\n", if prev then JSON.stringify(prev, null, 2) else null
            console.log "current:\n", JSON.stringify(query, null, 2)
            console.groupEnd()

            $rootScope.queryState.changeCounter += 1
            $rootScope.queryState.previous = _.cloneDeep(query)
            $rootScope.query = query
            $rootScope.queryStr = try JSON.stringify(query, null, 2)
            broadcastQueryChange(query)

        $rootScope.initialized = true
        $rootScope.query = DashboardQuery.get()
        LoadingStatus.done()

    ).catch (error) ->

        if error instanceof DataLoadingInProgressError
            $rootScope.initialized = false
            LoadingStatus.loading(LoadingStatus.Messages.LoadingDataFromAppInit())
            $scope.$watch 'DashboardDatabaseStatus.state.status.isLoading', (currentIsLoading) ->
                return if SIMULATE_DASH_NOT_READY
                $window.location.reload() if currentIsLoading is false
            analytics.track("loading-start", {status: DashboardDatabaseStatus.get()})
            return

        if error instanceof UserSuspendedError
            $rootScope.initialized = false
            LoadingStatus.error(LoadingStatus.Messages.SuspendedUserMessage($rootScope.organizationId))
            return

        if error instanceof DashboardSuspendedFrasersGroupExternalError
            $rootScope.initialized = false
            LoadingStatus.dashboardSuspended(LoadingStatus.Messages.TemporarilySuspendedDashboardFrasersGroupExternalMessage($rootScope.organizationId))
            return

        if error instanceof DashboardSuspendedFrasersGroupInternalError
            $rootScope.initialized = false
            LoadingStatus.dashboardSuspended(LoadingStatus.Messages.SuspendedDashboardFrasersGroupInternalMessage($rootScope.organizationId))
            return

        if error instanceof DashboardSuspendedPaymentError
            $rootScope.initialized = false
            LoadingStatus.dashboardSuspended(LoadingStatus.Messages.SuspendedDashboardMessage($rootScope.organizationId))
            return

        if error instanceof DashboardSuspendedError
            $rootScope.initialized = false
            LoadingStatus.dashboardSuspended(LoadingStatus.Messages.SuspendedDashboardMessage($rootScope.organizationId))
            return

        return $q.reject(error)


module.factory('AccessControl', ['StorageAPI', (StorageAPI) ->

    fetch = ->
        return StorageAPI('accessControl').then((api) -> api.get())

    normalizeStatus = (status) ->
        status = status or 'active'
        status = status.toString().toLowerCase()
        return status

    return fetch().then (accessControl) ->
        accessControl = accessControl or {}
        accessControl.status = normalizeStatus(accessControl.status)
        accessControl.filters ?= {}
        return accessControl
])

module.service('QueryMetrics', ['$rootScope', '$q',
###*
@param {import('./main-controller').DashboardRootScope} $rootScope
@param {import('angular').IQService} $q
@returns {import('./main-controller').IQueryMetrics}
###
($rootScope, $q) ->
    QueryMetrics = QueryMetricsAPI({ ConfigAPI })

    # FIXME: Logic can be out-of-sync with the lib... we should rework this method.
    applyCurrencyToMetrics: (metrics, currencyOrId) -> QueryMetrics.applyCurrencyToMetrics metrics, do ->
        currencyOrId ?= $rootScope.currencyModel?.selected
        return if not currencyOrId
        return currencyOrId if typeof currencyOrId isnt 'string'
        available = $rootScope.currencyModel?.available ? []
        currencyOrId = currencyOrId.toLowerCase()
        currencyOrId = available.find((x) -> x.id is currencyOrId)
        return currencyOrId

    fetch: (currency) -> $q.when do ->
        return QueryMetrics.fetch(currency)
])

module.service('HourProperty', ['$q', 'CONFIG', ($q, CONFIG) ->
    fetch: -> $q.when(fetchHourProperty(CONFIG.flags))
])

module.service('CalendarProperties', ['$q', ($q) ->
    fetch: -> $q.when(DatabaseDescriptorsService.fetch()).then((d) -> fetchCalendarProperties(d))
])
