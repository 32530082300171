import angular from 'angular';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import { Routes } from '../../router';
import { IConfigObj, IOrganization, User } from '../../lib/types';
import { buildSupportLink } from '../../controllers/main/support-email.helper';

export const UserMenuDirective = () => [
    function UserMenu() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="user-menu dropdown">

                    <!-- this is the section that's visible in the top bar -->
                    <article class="user-menu-face dropdown-toggle">
                        <img
                            class="organization-logo"
                            ng-src="{{ model.organization.logo }}"
                            on-error-src="onLogoError()"
                            ng-if="!logoError && model.organization.logo"
                        >
                        <section class="labels" ng-class="{ 'no-logo': logoError || !model.organization.logo }">
                            <span class="organization-label">{{ model.organization.label }}</span>
                            <span class="user-label">{{ model.user.name || model.user.email }}</span>
                        </section>
                        <i class="icon-menu hide-small"></i>
                    </article>

                    <!-- enabled if user has multiple organizations -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length > 1">
                        <li class="submenu">
                            <h1>Available Dashboards</h1>
                            <user-menu-organization-list model="model"></user-menu-organization-list>
                        </li>
                        <li class="submenu">
                            <h1>Actions</h1>
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>

                    <!-- enabled if user has only one org -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length == 1">
                        <li class="submenu">
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>
                </article>
            `,
            link: function UserMenuLink($scope: angular.IScope & { logoError: boolean; onLogoError: () => void }) {
                $scope.logoError = false;

                $scope.onLogoError = () => ($scope.logoError = true);
            },
        };
    },
];
export const UserMenuMobileDirective = () => [
    function UserMenuMobile() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="user-menu-mobile dropdown">

                    <!-- this is the section that's visible in the top bar -->
                    <article class="user-menu-face dropdown-toggle">
                        <img
                            class="organization-logo-mobile"
                            ng-src="{{ model.organization.logo }}"
                            on-error-src="onLogoError()"
                            ng-if="!logoError && model.organization.logo"
                        >
                        <section class="labels-mobile" ng-class="{ 'no-logo': logoError || !model.organization.logo }">
                            <span class="organization-label">{{ model.organization.label }}</span>
                            <span class="user-label">{{ model.user.name || model.user.email }}</span>
                        </section>
                        <i class="icon-menu"></i>
                    </article>

                    <!-- enabled if user has multiple organizations -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length > 1">
                        <li class="submenu">
                            <h1>Available Dashboards</h1>
                            <user-menu-organization-list model="model"></user-menu-organization-list>
                        </li>
                        <li class="submenu">
                            <h1>Actions</h1>
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>

                    <!-- enabled if user has only one org -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length == 1">
                        <li class="submenu">
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>
                </article>
            `,
            link: function UserMenuLink($scope: angular.IScope & { logoError: boolean; onLogoError: () => void }) {
                $scope.logoError = false;

                $scope.onLogoError = () => ($scope.logoError = true);
            },
        };
    },
];

export const UserMenuOrganizationListDirective = () => [
    '$document',
    'OutsideElementClick',
    function UserMenuOrganizationList($document: angular.IDocumentService, OutsideElementClick: IOutsideElementClick) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div class="organizations">
                    <div class="user-menu-organizations-search-box filter-input-search"
                        ng-if="model.organizations.length>4">
                        <input
                            type="text"
                            spellcheck="false"
                            placeholder="search..."
                            ng-model="search.id"
                        >
                        <i class="icon-search"></i>
                    </div>
                    <ul>
                        <li class="dropdown-menu-item"
                            ng-repeat="organization in model.organizations | orderBy: 'label' | fuzzyBy: 'label': search.id"
                            ng-click="model.setOrganization(organization.id)"
                            ng-class="{active: organization.id == model.organization.id}">
                            {{ organization.label }}
                        </li>
                    </ul>
                </div>
            `,
            link: function UserMenuOrganizationListLink($scope: angular.IScope & { search: { id: string } }) {
                $scope.search = { id: '' };
                const $dropdownElement = $document.find('.user-menu-dropdown.dropdown-menu');
                OutsideElementClick($scope, $dropdownElement, () => {
                    $scope.search.id = '';
                });
            },
        };
    },
];

export interface IUserMenuActionsScope extends angular.IScope {
    model: {
        flags: {
            kiosk?: boolean;
            reporting?: boolean;
        };
        user: User;
        adminUrl: string;
        organization: IOrganization;
        logout: () => void;
    };

    onSupportClick: () => void;
    goToUserAdminPage: () => void;
    goToReportingPage: () => void;
    goToCounterPage: () => void;
    onHandbookClick: () => void;
    links: {
        support?: string | undefined;
    };
}

export const UserMenuActionsDirective = () => [
    '$location',
    'ROUTES',
    'CONFIG',
    function UserMenuActions(
        $location: angular.ILocationService,
        ROUTES: Routes,
        CONFIG: IConfigObj,
    ): angular.IDirective<IUserMenuActionsScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <ul class="user-menu-actions">
                    <status-message-bar-button class="hide-large"></status-message-bar-button>
                    <li class="reporting hide-large" ng-click="onSupportClick()">
                        <i class="icon-paper-plane">Support</i>
                    </li>
                    <li class="reporting" ng-click="goToCounterPage()" ng-if="model.flags.kiosk">
                        <i class="icon-picture">Kiosk Mode</i>
                    </li>
                    <li class="reporting" ng-click="goToReportingPage()" ng-if="model.flags.reporting">
                        <i class="icon-docs">Reporting</i>
                    </li>
                    <li class="admin" ng-click="goToUserAdminPage()" ng-if="model.user.isAdmin() && model.adminUrl">
                        <i class="icon-vcard">User Admin</i>
                    </li>
                    <li class="logout" ng-click="model.logout()">
                        <i class="icon-logout">Logout</i>
                    </li>
                </ul>
            `,
            link: scope => {
                scope.onSupportClick = () => {
                    if (scope.links.support) {
                        window.open(scope.links.support, '_blank');
                        return;
                    }

                    window.open('mailto:support@42technologies.com?subject=Support request');
                };
                scope.goToUserAdminPage = () => {
                    window.open(`${scope.model.adminUrl}/organizations/${scope.model.organization.id}/users`);
                };
                scope.goToReportingPage = () => {
                    if (!ROUTES.reportingReports) {
                        throw new Error('[user-menu] Reporting route is not defined!');
                    }

                    $location.path(ROUTES.reportingReports.navigationUrl ?? ROUTES.reportingReports.url);
                };
                scope.goToCounterPage = () => {
                    if (!ROUTES.counter) {
                        throw new Error('[user-menu] Counter route is not defined!');
                    }
                    $location.path(ROUTES.counter.url);
                };

                scope.links = {};
                scope.$watch('model.organization', () => {
                    scope.links.support = buildSupportLink(CONFIG, scope.model.organization);
                });
            },
        };
    },
];
