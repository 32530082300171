import _ from 'lodash';
import { ICustomerStatsResponse } from '../../../modules/services/query-service.types';

export type ICustomerStatParseFunction = (stats: ICustomerStatsResponse, $filter: angular.IFilterService) => string;

const handleNilValue = (value: unknown, defaultValue = '-'): string => {
    return _.isNil(value) ? defaultValue : String(value);
};

export const CUSTOMER_STATS_CONFIG: Record<
    string,
    {
        title: string;
        type: string;
        value: string | ICustomerStatParseFunction;
        avg: string | ICustomerStatParseFunction;
    }
> = {
    customer_count: {
        title: 'Customers',
        type: 'number',
        value: (stats, $filter) => handleNilValue($filter('number')(stats.customer_count)),
        avg: 'make them feel special!',
    },
    total_spent: {
        title: 'Total Spent',
        type: 'money',
        value: (stats, $filter) => handleNilValue($filter('money')(stats.total_spent)),
        avg: (stats, $filter) => {
            const outputValue = handleNilValue($filter('money')(stats.avg_total_spent));
            return `that's about <em>${outputValue}</em> per customer`;
        },
    },
    transaction_count: {
        title: 'Transactions',
        type: 'number',
        value: (stats, $filter) => handleNilValue($filter('number')(stats.transaction_count, 0)),
        avg: (stats, $filter) => {
            const outputValue = handleNilValue($filter('number')(stats.avg_transaction_count, 1));
            return `which is roughly <em>${outputValue}</em> per customer`;
        },
    },
    item_count: {
        title: 'Items Bought',
        type: 'number',
        value: (stats, $filter) => handleNilValue($filter('number')(stats.item_count, 0)),
        avg: (stats, $filter) => {
            const outputValue = handleNilValue($filter('number')(stats.avg_item_count, 1));
            return `which is about <em>${outputValue}</em> per transaction`;
        },
    },
};

export interface CustomerStatsItem {
    title: string;
    value: string;
    avg: string;
    type?: string;
}

export const CustomerStatsDirective = () => [
    function CustomerState() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            template: `
                <ul class="customer-stats card stats" query="query">
                    <li class="customer-stat" ng-repeat="stat in model.stats">
                        <div class="title" ng-bind-html="stat.title"></div>
                        <div class="value" ng-bind-html="stat.value"></div>
                        <div class="avg" ng-bind-html="stat.avg"></div>
                    </li>
                    <div class="loadable" ng-class="{loading: model.stats === null}"></div>
                </ul>
            `,
        };
    },
];
