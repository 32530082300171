import _ from 'lodash';
import type angular from 'angular';
import 'angular-promise-tracker';
import type { AngularInjected } from '../../lib/angular';
import * as Analytics from '../../lib/analytics';
import * as ConfigFlags from '../../lib/config-flags';
import * as Auth from '../../lib/auth';
import type { IMetricDefinition } from '../../lib/types';
import type { IQueryMetrics } from '../main-controller';
import type { IOverviewChartMetricsConfigService } from './overview-metrics.service';

export interface IOverviewStat {
    title: string;
    inverted?: boolean;
    type?: string;
    actual: IMetricDefinition;
    percentage: null | IMetricDefinition;
    comparison: null | IMetricDefinition;
    tracker?: angular.promisetracker.PromiseTracker;
}

export type IOverviewOverallStats = AngularInjected<typeof OverviewOverallStats>;

let loggedErrors = false;
export const OverviewOverallStats = () => [
    '$q',
    'promiseTracker',
    'QueryMetrics',
    'SalesChartMetricsConfig',
    function SaleChartsService(
        $q: angular.IQService,
        promiseTracker: angular.promisetracker.PromiseTrackerService,
        QueryMetrics: IQueryMetrics,
        SalesChartMetricsConfig: IOverviewChartMetricsConfigService,
    ) {
        const fetchCharts = async (): Promise<IOverviewStat[]> => {
            const [orgId, config, metrics] = await Promise.all([
                Auth.getOrganization(),
                SalesChartMetricsConfig.fetch(),
                QueryMetrics.fetch(),
            ]);

            if (Auth.isAllsaintsOrganizationId(orgId)) {
                return fetchAllSaintsConfig(metrics);
            }

            const metricsByField = _.keyBy(metrics, x => x.field);

            const resolveMapping = (field: null | string): [def: null | IMetricDefinition, error: null | Error] => {
                if (field === null) return [null, null];
                const metric = metricsByField[field];
                if (metric) return [metric, null];
                const error = new Error(field);
                return [null, error];
            };

            let errors: Error[] = [];
            const resolved = (config ?? []).map(metric => {
                const type = typeof metric.cellFilter === 'string' ? metric.cellFilter : null;
                const mapping = getComparisonMapping(orgId, metrics, metric.field);
                const [comparison, error1] = resolveMapping(mapping.comparison);
                const [percentage, error2] = resolveMapping(mapping.percentage);
                errors = [...errors, ...[error1, error2].filter(Boolean)];
                return {
                    title: metric.headerGroup,
                    inverted: metric.inverted,
                    actual: metric,
                    comparison,
                    percentage,
                    ...(type ? { type } : {}),
                };
            });

            // NOTE: Filtering out well-known errors...
            errors = errors.filter(error => !error.message.includes('abandoned_carts_sales'));

            if (!loggedErrors && errors.length > 0) {
                loggedErrors = true;
                const error = new Error(
                    `[overview][${orgId}] Missing metrics for overview stats...\n${errors
                        .map(x => x.message)
                        .join('\n')}`,
                );
                Analytics.logError(error);
            }

            return resolved;
        };

        const fetch = async () => {
            const charts = await fetchCharts();
            const resolved = charts.map(chart => ({ ...chart, tracker: promiseTracker() }));
            return _.uniqBy(resolved, x => x.actual.field);
        };

        return {
            fetch: (): angular.IPromise<IOverviewStat[]> => {
                return $q.when(fetch());
            },
        };
    },
];

// FIXME: [DEV-1811] Hardcoded metrics

function getComparisonMapping(organizationId: string, metrics: IMetricDefinition[], field: string) {
    const hasTaxes =
        metrics.some(metric => metric.field === 'net_sales_without_taxes') ||
        metrics.some(metric => metric.field === 'demand_net_sales_without_taxes');
    const override = ORGS_OVERRIDES[organizationId];
    return override ? override(metrics, field) : getStandardComparisonMapping(metrics, field, { hasTaxes });
}

const ORGS_OVERRIDES: Record<
    string,
    (metrics: IMetricDefinition[], field: string) => { comparison: null | string; percentage: null | string }
> = {
    sportsdirect: (metrics: IMetricDefinition[], field: string) => {
        switch (field) {
            case 'store_budget':
                return { comparison: 'net_sales', percentage: 'diff_to_store_budget_percentage' };
            case 'store_target':
                return { comparison: 'net_sales', percentage: 'diff_to_store_target_percentage' };
            default:
                return getStandardComparisonMapping(metrics, field);
        }
    },
    hatchcollection: (metrics: IMetricDefinition[], field: string) => {
        switch (field) {
            case 'gross_sales_rof':
                return {
                    comparison: 'gross_sales',
                    percentage: 'diff_to_gross_sales_rof',
                };
            case 'gross_sales_plan':
                return {
                    comparison: 'gross_sales',
                    percentage: 'diff_to_gross_sales_plan',
                };
            default:
                return getStandardComparisonMapping(metrics, field);
        }
    },
    mizzenandmain: (metrics: IMetricDefinition[], field: string) => {
        switch (field) {
            case 'store_budget_traffic':
                return {
                    comparison: 'traffic',
                    percentage: 'diff_to_store_budget_traffic_percentage',
                };
            case 'budget_conversion':
                return {
                    comparison: 'conversion',
                    percentage: 'diff_to_budget_conversion_percentage',
                };
            case 'store_budget_demand_dollar_per_transaction':
                return {
                    comparison: 'demand_gross_dollar_per_transaction',
                    percentage: 'diff_to_store_budget_demand_dollar_per_transaction_percentage',
                };
            case 'budget_demand_transaction_count':
                return {
                    comparison: 'demand_transaction_count',
                    percentage: 'diff_to_budget_demand_transaction_count_percentage',
                };
            case 'budget_demand_gross':
                return {
                    comparison: 'demand_gross_sales',
                    percentage: 'diff_to_budget_demand_gross_percentage',
                };
            default:
                return getStandardComparisonMapping(metrics, field);
        }
    },
    sony_rfp_demo: (metrics: IMetricDefinition[], field: string) => {
        switch (field) {
            case 'net_sales_units':
                return {
                    comparison: 'net_sales_units_budget',
                    percentage: 'diff_to_net_sales_units_budget_percentage',
                };
            default:
                return getStandardComparisonMapping(metrics, field);
        }
    },
    nililotan: (metrics: IMetricDefinition[], field: string) => {
        switch (field) {
            case 'budget_stretch':
                return { comparison: 'net_sales', percentage: `diff_to_${field}_percentage` };
            default:
                return getStandardComparisonMapping(metrics, field);
        }
    },
    verabradley: (metrics: IMetricDefinition[], field: string) => {
        switch (field) {
            case 'demand_gross_plan':
                return { comparison: 'demand_gross_sales', percentage: `diff_to_${field}_percentage` };
            case 'aov_plan':
                return { comparison: 'demand_gross_dollar_per_transaction', percentage: `diff_to_${field}_percentage` };
            case 'conversion_plan':
                return { comparison: 'demand_online_conversion', percentage: `diff_to_${field}_percentage` };
            case 'sessions_plan':
                return { comparison: 'sessions', percentage: `diff_to_${field}_percentage` };
            case 'demand_gross_transaction_plan':
                return { comparison: 'demand_transaction_count', percentage: `diff_to_${field}_percentage` };
            default:
                return getStandardComparisonMapping(metrics, field);
        }
    },
    totes: (metrics: IMetricDefinition[], field: string) => {
        switch (field) {
            case 'future_on_order_units':
                return { comparison: null, percentage: null };
            case 'future_on_order_at_price':
                return { comparison: null, percentage: null };
            default:
                return getStandardComparisonMapping(metrics, field);
        }
    },
};

function getStandardComparisonMapping(
    metrics: IMetricDefinition[],
    field: string,
    options?: { hasTaxes: boolean },
): { comparison: null | string; percentage: null | string } {
    switch (field) {
        case 'budget':
        case 'latest_estimate': {
            const comparison = options?.hasTaxes ? 'net_sales_without_taxes' : 'net_sales';
            const percentage = options?.hasTaxes
                ? `diff_to_${field}_percentage_without_taxes`
                : `diff_to_${field}_percentage`;
            return { comparison, percentage };
        }

        case 'budget_profit':
            return { comparison: 'profit', percentage: 'diff_to_budget_profit_percentage' };

        // hatch only?
        case 'gross_sales_plan':
            return { comparison: 'gross_sales', percentage: 'diff_to_gross_sales_plan' };

        default: {
            const comparison = `growth_${field}_prev`;
            const percentage = `growth_${field}`;
            return { comparison, percentage };
        }
    }
}

const fetchAllSaintsConfig = async (metrics: IMetricDefinition[]): Promise<IOverviewStat[]> => {
    const [user, flags] = await Promise.all([Auth.getUser(), ConfigFlags.fetch()]);
    const metricsByField = _.keyBy(metrics, x => x.field);

    type AllsaintsChart = Omit<IOverviewStat, 'actual' | 'comparison' | 'percentage'> & {
        actual: undefined | IMetricDefinition;
        comparison?: undefined | IMetricDefinition;
        percentage?: undefined | IMetricDefinition;
    };
    const partial: AllsaintsChart[] = [
        {
            title: 'Gross Sales',
            actual: metricsByField['net_sales'],
            type: 'money',
        },
        {
            title: 'Nett Sales',
            actual: metricsByField['net_sales_without_taxes'],
            type: 'money',
        },
        {
            title: 'Gross Sales Excl. Returns',
            actual: metricsByField['gross_sales'],
            type: 'money',
        },
        {
            title: 'Gross Return',
            actual: metricsByField['returned_sales'],
            type: 'money',
            inverted: true,
        },
        ...(!flags.showBudget
            ? []
            : [
                  {
                      title: 'Forecast',
                      actual: metricsByField['budget'],
                      comparison: metricsByField['net_sales_without_taxes'],
                      percentage: metricsByField['diff_to_budget_percentage_without_taxes'],
                      type: 'money',
                  },
              ]),
        {
            title: 'Footfall',
            actual: metricsByField['traffic'],
            type: 'number:0',
        },
        {
            title: 'Conversion',
            actual: metricsByField['conversion'],
            type: 'percent',
        },
        {
            title: 'Transactions',
            actual: metricsByField['transaction_count'],
            type: 'number',
        },
        {
            title: 'Gross Sales Units',
            actual: metricsByField['net_sales_units'],
            type: 'number',
        },
        {
            title: 'Gross UPT',
            actual: metricsByField['gross_sales_units_per_transaction'],
            type: 'number:2',
        },
        {
            title: 'Gross ATV',
            actual: metricsByField['gross_dollar_per_transaction'],
            type: 'money',
        },
        ...(user.name === 'digital dashboard'
            ? []
            : [
                  {
                      title: 'Gross Margin %',
                      actual: metricsByField['net_sales_margin'],
                      type: 'percent',
                  },
              ]),
    ];

    function* normalize(charts: AllsaintsChart[]): Iterable<IOverviewStat> {
        for (const chart of charts) {
            const actual = chart.actual;
            if (!actual) continue;
            const comparison = !('comparison' in chart)
                ? metricsByField[`growth_${actual.field}_prev`]
                : chart.comparison;
            const percentage = !('percentage' in chart) ? metricsByField[`growth_${actual.field}`] : chart.percentage;
            yield {
                ...chart,
                actual,
                percentage: percentage ?? null,
                comparison: comparison ?? null,
            };
        }
    }

    return Array.from(normalize(partial));
};
